import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    container: {
      height: '100%',
      overflowY: 'auto',
      backgroundColor: '#FCFCFD'
    }
  })
);
