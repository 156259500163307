import { ClickAwayListener, IconButton } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import * as React from 'react';
import { AppContext } from '../../../../../context/app/AppContext';
import { UserRightsType } from '../../../../../graphql/generated/graphql';
import StatusFilterMenu from '../../../Filters/StatusFilters/StatusFilterMenu';

export default function StatusFilterButton() {
  const { appContext } = React.useContext(AppContext);
  const [open, setOpen] = React.useState(false);
  const buttonRef = React.useRef(null);

  if (!appContext.userRights?.includes(UserRightsType.MessagesUpdateStatus)) return null;

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          setOpen(false);
        }}
      >
        <IconButton
          ref={buttonRef}
          className='filters'
          onClick={() => {
            setOpen(true);
          }}
          color='primary'
          aria-label='Show filters'
          component='span'
        >
          <FilterList />
        </IconButton>
      </ClickAwayListener>
      <StatusFilterMenu isOpen={open} anchorElement={buttonRef.current} />
    </>
  );
}
