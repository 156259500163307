import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { BORDER, CONTENT_HEIGHT, HEADER_HEIGHT, HIGHLIGHT_COLOR } from '../../../../styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#FCFCFD'
    },
    list: {
      margin: '15px 0 0',
      padding: '0 15px',
      height: `calc(${CONTENT_HEIGHT} - ${HEADER_HEIGHT} - 180px)`,
      overflowY: 'auto',
      borderBottom: BORDER
    },
    alertContainer: {
      margin: '.5rem .5rem'
    },
    categoryLabel: {
      position: 'absolute',
      top: '19px',
      right: '10px',
      backgroundColor: HIGHLIGHT_COLOR,
      padding: '3px 8px',
      alignItems: 'center',
      borderRadius: '10px',
      display: 'flex',

      '& p': {
        fontSize: '.9rem',
        color: theme.palette.text.secondary,
        marginLeft: '.2rem'
      }
    },
    labelIcon: {
      color: theme.palette.text.secondary,
      width: '1rem',
      height: '1rem',
      transform: 'rotate(220deg)'
    }
  })
);
