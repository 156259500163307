import React, { FC, useRef } from 'react';
import styles from './styles';
import { IconButton, Typography } from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { formatBytes } from '../../../utils/validator';
import ClearIcon from '@material-ui/icons/Clear';

interface Props {
  onFileChange: (_attachment: File | undefined) => void;
  file?: File;
}
const EditorAttachment: FC<Props> = (props) => {
  const classes = styles();
  const attachmentInputRef = useRef<HTMLInputElement | null>(null);

  // eslint-ignore-next-line
  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    if (!target.validity || !target.files || !target.files.length) return;

    props.onFileChange(target.files[0]);

    clearInput();
  };

  const clearInput = () => {
    if (!attachmentInputRef || !attachmentInputRef.current) return;

    attachmentInputRef.current.value = '';
    attachmentInputRef.current.dispatchEvent(new Event('change'));
  };

  const onClear = () => {
    clearInput();
    props.onFileChange(undefined);
  };

  return (
    <div className={classes.attachmentContainer}>
      <input
        ref={attachmentInputRef}
        id='messaging-attachment'
        style={{ display: 'none' }}
        onChange={onFileChange}
        type='file'
        data-testid='messaging-attachment-input'
      />
      <IconButton
        onClick={() => attachmentInputRef.current && attachmentInputRef.current.click()}
        className='attachment-icon'
        aria-label='upload attachment'
      >
        <AttachmentIcon />
      </IconButton>

      {props.file && (
        <>
          <Typography className={`${classes.attachmentName} truncate`}>{`${
            props.file.name
          } (${formatBytes(props.file.size)})`}</Typography>
          <IconButton
            onClick={onClear}
            className='attachment-clear'
            aria-label='clear'
            data-testid='attachment-clear'
          >
            <ClearIcon />
          </IconButton>
        </>
      )}
    </div>
  );
};

export default EditorAttachment;
