import React from 'react';
import { CSSTransition } from 'react-transition-group';
import styles from './styles';

interface Props {
  in: boolean;
  className?: string;
}

export const TransitionGroup: React.FC<Props> = ({ in: animateIn, children, className }) => {
  const classes = styles();

  return (
    <div className={`${classes.root} ${className ? className : ''}`}>
      <CSSTransition in={animateIn} timeout={0} className='messaging-transition' appear>
        <div>{children}</div>
      </CSSTransition>
    </div>
  );
};
