import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { MOBILE_BREAKPOINT } from '../../styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    messaging: {
      '& .filters-column': {
        width: '20%'
      },

      '& .listing-column': {
        width: '25%'
      },

      '& .conversation-column': {
        width: '55%'
      },

      [theme.breakpoints.between(MOBILE_BREAKPOINT, 'md')]: {
        '& .filters-column': {
          display: 'none'
        }
      },

      [theme.breakpoints.down('md')]: {
        '& .listing-column': {
          width: '40%'
        },

        '& .conversation-column': {
          width: '60% !important'
        }
      },

      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        '& .filters-column': {
          '&:not(.active)': {
            display: 'none'
          }
        },
        '& .conversation-column, .listing-column': {
          width: '100% !important',
          border: 'none'
        }
      }
    }
  })
);
