import React from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import useStyles from './styles';

interface LoadingSpinnerProps {
  text?: string;
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.loading} loading-spinner`}>
      <CircularProgress className='progress' />
      {text && <Typography className='text'>{text}</Typography>}
    </div>
  );
};
