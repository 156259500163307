import { IconButton, TextField } from '@material-ui/core';
import React, { useContext, useRef } from 'react';
import styles from './styles';
import ClearIcon from '@material-ui/icons/Clear';
import { t } from 'i18next';
import { AppContext } from '../../../../../context/app/AppContext';

interface SearchFieldProps {
  value: string;
  onFocus: any; // eslint-disable-line
  onInput: any; // eslint-disable-line
  onBlur: any; // eslint-disable-line
}

const SearchField = ({ value, onFocus, onInput, onBlur }: SearchFieldProps) => {
  const { appContext, dispatch } = useContext(AppContext);
  const classes = styles();
  const fieldRef = useRef();

  const onClear = () => {
    dispatch({ ...appContext, searchActive: false });
  };

  return (
    <div
      className={`${classes.container} ${
        appContext.apartments.length === 1 && 'no-apartments-filter'
      }`}
    >
      <TextField
        inputRef={fieldRef}
        className='message-search input'
        value={value}
        onChange={onInput}
        onFocus={onFocus}
        onBlur={onBlur}
        variant='outlined'
        placeholder={t('search')}
        autoComplete='off'
        inputProps={{ 'data-testid': 'search-field' }}
      />
      {appContext.searchActive && (
        <IconButton
          onClick={onClear}
          className='message-search-clear'
          aria-label='clear'
          data-testid='search-clear'
        >
          <ClearIcon />
        </IconButton>
      )}
    </div>
  );
};

export default SearchField;
