import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    loading: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '20px',

      '& .progress': {
        margin: '1rem auto'
      },

      '& p': {
        margin: '1rem auto'
      }
    }
  })
);
