export const ACCEPTED_USER_LEVELS = [3, 4, 7, 10, 11, 12];

/* eslint-disable */
export enum ClientType {
  CoreClient = 'core-client',
  CustomerJourney = 'customer-journey',
  Mobile = 'mobile'
}

export enum Paths {
  ApartmentMessages = '/messages',
  Bulletins = '/messages/bulletins'
}
/* eslint-enable */
