import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as React from 'react';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';
import { AppContext } from '../../../../context/app/AppContext';
import {
  ConversationCategory,
  Message,
  useMessageCreatedSubscription,
  useMessagesQuery
} from '../../../../graphql/generated/graphql';
import { Header } from '../Header/Header';
import NewMessageForm from '../NewMessageForm/NewMessageForm';
import styles from './styles';
import { Apartment } from '../../../../interfaces';
import { useTranslation } from 'react-i18next';
import { logError } from '../../../../utils/errors';
import { MOBILE_BREAKPOINT } from '../../../../styles';
import MessageGroup from './MessageGroup/MessageGroup';
import { TransitionGroup } from '../../../../components/TransitionGroup/TransitionGroup';

const ActiveConversation: React.FC = () => {
  const { appContext, dispatch } = React.useContext(AppContext);
  const [loaded, setLoaded] = React.useState(false);
  const [activeApartmentId, setActiveApartmentId] = React.useState<number>(-1);
  const category = appContext.categories?.find(
    (category: ConversationCategory) =>
      category.uuid === appContext.activeConversation?.categoryUuid
  )?.name;
  const { data, error, loading, refetch } = useMessagesQuery({
    variables: { conversationUuid: appContext.activeConversation?.conversationUuid as string },
    onCompleted: () => {
      if (data) setActiveApartmentId(data.conversation?.conversation.apartmentId as number);
      setLoaded(true);
    }
  });
  const { data: subscriptionData } = useMessageCreatedSubscription({
    variables: { apartmentId: activeApartmentId }
  });
  const classes = styles();
  const { t } = useTranslation();
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down(MOBILE_BREAKPOINT));

  React.useEffect(() => {
    refetch();
  }, [subscriptionData?.messageCreated?.message?.uuid]);

  React.useEffect(() => {
    if (!loaded) return;

    dispatch({ ...appContext, updateListing: true });
    setLoaded(false);
  }, [loaded]);

  const backButtonClicked = () => {
    dispatch({ ...appContext, activeConversation: undefined, searchActive: false });
  };

  if (loading) {
    return <LoadingSpinner />;
  } else if (error || !data || !data.conversation) {
    logError(error);
    return (
      <Alert className={classes.alertContainer} severity='error'>
        {t('error.FAILED_TO_FETCH_CONVERSATION')}
      </Alert>
    );
  }

  const { conversation, messages } = data.conversation;

  const apartment: Apartment = appContext.apartments.find(
    (apartment: Apartment) => apartment.id === conversation.apartmentId
  ) as Apartment;

  const getOrderedAndGroupedMessages = () => {
    const orderedMessages = messages.slice(0).reverse();
    const groupedMessages = {};
    let groupIndex = 0;

    const firstMessage = orderedMessages[0] as Message;
    let latestName = firstMessage.user?.name;

    // eslint-disable-next-line
    orderedMessages.forEach((m: any, i: number) => {
      const name = m.user?.name;

      if (name !== latestName && i > 0) {
        groupIndex++;
        latestName = name;
      }

      if (!groupedMessages[groupIndex]) {
        groupedMessages[groupIndex] = {
          uuid: m.uuid,
          name,
          date: m.sentDate,
          isYou: m.user.isYou,
          avatar: m.user.avatar,
          messages: []
        };
      }

      groupedMessages[groupIndex].messages.push(m);
    });

    groupedMessages[groupIndex].lastGroup = true;

    return groupedMessages;
  };

  return (
    <TransitionGroup in={!loading} className={classes.root}>
      <Header showBackButton={mobileSize} onBackButtonClicked={backButtonClicked}>
        <Typography data-testid='conversation-subject' className='subject truncate'>
          {conversation.subject}
        </Typography>
        <Typography className='category truncate'>{t('categories.' + category)}</Typography>
        <Typography className='apartment truncate'>{apartment.name}</Typography>
      </Header>
      <ul className={`${classes.list} message-list`}>
        {
          // eslint-disable-next-line
          Object.values(getOrderedAndGroupedMessages()).map((group: any, i: number) => {
            return <MessageGroup key={`message-group-${i}`} {...group} />;
          })
        }
      </ul>
      <NewMessageForm />
    </TransitionGroup>
  );
};

export default ActiveConversation;
