import React, { useContext } from 'react';
import { AppContext } from '../../../context/app/AppContext';
import CreateConversationForm from './CreateConversationForm/CreateConversationForm';
import styles from './styles';
import ActiveConversation from './ActiveConversation/ActiveConversation';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { MOBILE_BREAKPOINT } from '../../../styles';
import NoActiveNotification from '../../../components/NoActiveNotification/NoActiveNotification';

const ConversationColumn: React.FC = () => {
  const { appContext } = useContext(AppContext);
  const { createNew: createNewConversation, activeConversation } = appContext;
  const classes = styles();
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down(MOBILE_BREAKPOINT));

  if (mobileSize && !appContext.activeConversation && !appContext.createNew) return null;

  const renderContent = () => {
    if (appContext.createNew) return <CreateConversationForm />;
    else if (appContext.activeConversation) return <ActiveConversation />;

    return <NoActiveNotification />;
  };

  return (
    <div
      className={`${classes.container} conversation-column ${
        createNewConversation || activeConversation ? 'active' : ''
      }`}
    >
      {renderContent()}
    </div>
  );
};

export default ConversationColumn;
