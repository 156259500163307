import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { BORDER, HEADER_HEIGHT, MOBILE_BREAKPOINT } from '../../../../styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    filterButtonsMobile: {
      width: '100%',
      borderBottom: BORDER,
      height: HEADER_HEIGHT,
      display: 'flex',
      justifyContent: 'flex-end',

      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        height: '3rem'
      }
    },
    listContainer: {
      height: `calc(100% - 10px)`,
      overflowY: 'auto',

      '& > div': {
        height: 'auto'
      }
    },
    list: {
      listStyle: 'none',
      padding: '0',
      margin: '0'
    },
    loading: {
      height: '100%',
      display: 'flex',

      '& .progress': {
        margin: 'auto'
      }
    },
    alertContainer: {
      margin: '1rem 1rem 0'
    }
  })
);
