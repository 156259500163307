import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    '@global': {
      '@keyframes gelatine': {
        'from, to': { transform: 'scale(1, 1)' },
        '25%': { transform: 'scale(0.95, 1.025)' },
        '50%': { transform: 'scale(1.025, 0.95)' },
        '75%': { transform: 'scale(0.95, 1.025)' }
      },
      '@keyframes hithere': {
        '50%': { transform: 'scale(1.1)' },
        '100%': { transform: 'scale(1)' }
      }
    },
    item: {
      listStyle: 'none',
      width: '100%',

      '& .speech-bubble': {
        display: 'flex',
        backgroundColor: '#F2F4F7',
        padding: '12px',
        flexDirection: 'column',
        borderRadius: '0 10px 10px 10px',

        '& p': {
          margin: 0,
          fontSize: '1rem'
        }
      },

      '&.isYou': {
        '& .speech-bubble': {
          backgroundColor: 'black',
          color: 'white',
          borderRadius: '10px 0 10px 10px'
        }
      },

      '&:not(.isYou)': {
        marginLeft: '10px'
      },

      '&:not(:first-child)': {
        marginTop: '5px'
      },

      '&:last-child': {
        marginBottom: '10px'
      },

      '& .msg': {
        font: 'inherit'
      },

      '& .animate': {
        animation: 'gelatine 1.5s ease',
        animationDelay: '.25s'
      }
    },
    attachmentContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'end'
    },
    link: {
      marginTop: '15px',
      marginLeft: '8px',

      '& .truncate': {
        fontSize: '.95rem !important'
      }
    }
  })
);
