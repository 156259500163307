import i18next from 'i18next';

export const formatLongDate = (dateStr: string): string => {
  return new Date(formatDateStr(dateStr)).toLocaleDateString(i18next.language, {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};

export const formatShortDate = (dateStr: string): string => {
  return new Date(formatDateStr(dateStr)).toLocaleDateString(i18next.language, {
    month: 'short',
    day: '2-digit',
    year: 'numeric'
  });
};

const formatDateStr = (dateStr: string) => {
  // replacing - with /, so that safari wont show invalid date
  return dateStr.replace(/-/g, '/');
};
