import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    expander: {
      position: 'relative',

      '&.expanded': {
        '& .truncate': {
          height: 'calc(100% - 1px)',
          whiteSpace: 'normal'
        },

        '& svg': {
          transform: 'rotate(180deg)'
        }
      }
    },
    content: {
      color: theme.palette.primary.main,
      width: 'auto',
      display: 'inline-flex',
      fontSize: '.9rem',
      maxWidth: 'calc(100% - 50px)',
      transition: 'height .5s'
    },
    expanderButton: {
      position: 'absolute',
      top: '-14px',
      right: '-5px',

      '& svg': {
        transition: 'transform .3s'
      }
    }
  })
);
