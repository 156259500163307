import { Typography } from '@material-ui/core';
import { t } from 'i18next';
import * as React from 'react';
import { AppContext } from '../../../../../context/app/AppContext';
import { Attachment, Message } from '../../../../../graphql/generated/graphql';
import { formatLongDate } from '../../../../../utils/date';
import MessageListItem from '../MessageListItem/MessageListItem';
import styles from './styles';

export interface MessageGroupProps {
  name: string;
  date: string;
  avatar: string;
  isYou: boolean;
  lastGroup: boolean;
  messages: Message[];
}

const MessageGroup: React.FC<MessageGroupProps> = ({
  name,
  avatar,
  isYou,
  lastGroup,
  messages
}) => {
  const classes = styles();
  const latestMessage = messages[messages.length - 1];
  const { appContext } = React.useContext(AppContext);
  const activeMessageUuid = appContext.activeConversation?.messageUuid;

  return (
    <li className={`${classes.group} ${isYou ? 'isYou' : ''}`}>
      {!isYou && (
        <img src={avatar} alt='avatar' className='message-avatar' data-testid='user-avatar' />
      )}
      <div className='info-content'>
        <Typography className='name truncate'>{isYou ? t('you') : name}</Typography>
        <Typography className='time'>{formatLongDate(latestMessage.sentDate)}</Typography>
      </div>
      <ul>
        {messages.map((message, i) => {
          const { uuid, content, user, attachment } = message;
          let scrollToMe = lastGroup && i === messages.length - 1;
          if (activeMessageUuid) scrollToMe = activeMessageUuid === uuid;

          return (
            <MessageListItem
              key={`message-list-item-${i}`}
              content={String(content)}
              isYou={user?.isYou || false}
              scrollToMe={scrollToMe}
              animate={activeMessageUuid === uuid}
              attachment={attachment as Attachment}
            />
          );
        })}
      </ul>
    </li>
  );
};

export default MessageGroup;
