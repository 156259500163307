import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    menu: {
      position: 'relative',

      '& ul': {
        padding: '.5rem'
      },

      '& p': {
        display: 'flex',

        '&:not(:last-child)': {
          paddingBottom: '6px'
        }
      },

      '& svg': {
        paddingRight: '10px'
      }
    },
    link: {
      textDecoration: 'none !important',
      borderBottom: 'none'
    }
  })
);
