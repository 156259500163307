import { ClickAwayListener, Typography } from '@material-ui/core';
import * as React from 'react';
import styles from './styles';
import ApartmentFilterMenu from '../../../../components/ApartmentFillterMenu/ApartmentFilterMenu';
import { useTranslation } from 'react-i18next';
import { KeyboardArrowDown } from '@material-ui/icons';
import { AppContext } from '../../../../context/app/AppContext';

const ApartmentFilterSelector: React.FC = () => {
  const { appContext } = React.useContext(AppContext);
  const [open, setOpen] = React.useState(false);
  const classes = styles();
  const buttonRef = React.useRef(null);
  const { t } = useTranslation();
  let selectedApartmentName = t('chooseApartment');

  if (appContext.selectedApartmentIds?.length === 1) {
    selectedApartmentName = appContext.apartments.find(
      (apartment) => apartment.id === (appContext.selectedApartmentIds as number[])[0]
    )?.name as string;
  }

  return (
    <div className={`${classes.filter} filter apartment-filter`}>
      <ClickAwayListener
        onClickAway={() => {
          setOpen(false);
        }}
      >
        <div
          className='button'
          ref={buttonRef}
          onClick={() => setOpen(true)}
          data-testid='apartment-filter-selector'
        >
          <Typography>{selectedApartmentName}</Typography>
          <KeyboardArrowDown className='down-arrow' />
        </div>
      </ClickAwayListener>
      <ApartmentFilterMenu isOpen={open} anchorElement={buttonRef.current} />
    </div>
  );
};

export default ApartmentFilterSelector;
