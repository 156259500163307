import React, { useContext, useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import Messaging from './routes/messaging';
import Bulletins from './routes/bulletins';
import Navigation from './components/Navigation/Navigation';
import { Paths } from './constants';
import styles from './styles';
import { AppContext } from './context/app/AppContext';
import {
  SanitationParameters,
  UserRightsType,
  useSettingsLazyQuery
} from './graphql/generated/graphql';
import { LoadingSpinner } from './components/LoadingSpinner/LoadingSpinner';
import { Alert } from '@material-ui/lab';
import { getErrorText } from './utils/errors';
import BulletinClipboardContextProvider from './context/bulletinClipboard/BulletinClipboardContextProvider';
import { BulletinClipboardContext } from './context/bulletinClipboard/BulletinClipboardContext';

const App: React.FC = () => {
  const [fetching, setFetching] = useState(false);
  const { appContext, dispatch } = useContext(AppContext);
  const { bulletinClipboardContext } = useContext(BulletinClipboardContext);
  const classes = styles();

  const [getSettings, { error, loading }] = useSettingsLazyQuery({
    variables: {
      apartmentIds: appContext.selectedApartmentIds as number[]
    },
    onCompleted: (data) => {
      dispatch({
        ...appContext,
        userRights: data.settings?.userRights as UserRightsType[],
        sanitationParameters: data.settings?.sanitationParameters as SanitationParameters
      });
    }
  });

  useEffect(() => {
    if (fetching) return;

    setFetching(true);
    getSettings();
  }, []);

  if (!fetching || loading) {
    return <LoadingSpinner />;
  } else if (error) {
    return (
      <Alert severity='error' className={classes.alert}>
        {getErrorText(error)}
      </Alert>
    );
  }

  return (
    <div className={`${classes.app} ${appContext.clientType}`}>
      <Navigation />
      <Route exact path={['/', Paths.ApartmentMessages]} component={Messaging} />
      <Route exact path={Paths.Bulletins}>
        <BulletinClipboardContextProvider initialContext={bulletinClipboardContext}>
          <Bulletins />
        </BulletinClipboardContextProvider>
      </Route>
    </div>
  );
};

export default App;
