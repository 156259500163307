import { useMediaQuery, useTheme } from '@material-ui/core';
import React, { useContext } from 'react';
import { AppContext } from '../../../context/app/AppContext';
import { MOBILE_BREAKPOINT } from '../../../styles';
import BulletinList from './BulletinList/BulletinList';
import styles from './styles';

const ListingColumn: React.FC = () => {
  const { appContext } = useContext(AppContext);
  const classes = styles();
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down(MOBILE_BREAKPOINT));

  if (mobileSize && (appContext.activeBulletin || appContext.createNew)) return null;

  return (
    <div className={`${classes.container} listing-column`} data-testid='bulletins-listing-column'>
      <BulletinList />
    </div>
  );
};

export default ListingColumn;
