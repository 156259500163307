import { Alert } from '@material-ui/lab';
import React, { useContext, useEffect, useState } from 'react';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';
import { AppContext } from '../../../../context/app/AppContext';
import {
  ConversationResponse,
  ConversationStatusEnum,
  Message,
  useConversationsAndMessagesLazyQuery
} from '../../../../graphql/generated/graphql';
import SearchListItem, { SearchListItemProps } from './SearchListItem/SearchistItem';
import SearchField from './SearchField/SearchField';
import styles from './styles';
import { TransitionGroup } from '../../../../components/TransitionGroup/TransitionGroup';
import { isMatch, validateSearchInput } from '../../../../utils/validator';
import { t } from 'i18next';

const Search: React.FC = () => {
  const { appContext, dispatch } = useContext(AppContext);
  const classes = styles();
  const [searchData, setSearchData] = useState<ConversationResponse[]>([]);
  const [searchInput, setSearchInput] = useState<string>('');
  const [getConversationsAndMessages, { data, loading, error }] =
    useConversationsAndMessagesLazyQuery({
      variables: {
        apartmentIds: appContext.selectedApartmentIds as number[],
        filters: appContext.filters || { conversationStatus: ConversationStatusEnum.Open }
      },
      onCompleted: () => {
        if (!data?.conversationsAndMessages) return;

        setSearchData(data.conversationsAndMessages as ConversationResponse[]);
      }
    });

  useEffect(() => {
    if (!appContext.searchActive) {
      deactivate();
    }
  }, [appContext.searchActive]);

  const deactivate = () => {
    setSearchData([]);
    setSearchInput('');
  };

  const onFocus = () => {
    if (!appContext.searchActive) {
      dispatch({ ...appContext, searchActive: true });
      getConversationsAndMessages();
    }
  };

  const onInput = ({ target }: { target: HTMLInputElement }) => {
    const val = target.value;
    setSearchInput(val);
  };

  const onBlur = ({ target }: { target: HTMLInputElement }) => {
    const searchValue = target.value;
    if (!searchValue.length) {
      dispatch({ ...appContext, searchActive: false });
    }
  };

  // returns matches from: message content, subject and user name
  const getFilteredResults = () => {
    if (!validateSearchInput(searchInput)) return [];

    const results: SearchListItemProps[] = [];
    searchData.forEach((data: ConversationResponse) => {
      const { conversation, messages } = data;

      messages.forEach((message: Message) => {
        const messageContentMatch = isMatch(String(message?.content), searchInput);
        const userNameMatch = isMatch(String(message?.user?.name), searchInput);
        if (messageContentMatch || userNameMatch) {
          results.push({ conversation, message, messageMatch: true } as SearchListItemProps);
        }
      });

      const subjectMatch = isMatch(String(conversation.subject), searchInput);

      if (!results.length && subjectMatch) {
        results.push({
          conversation,
          message: messages[0],
          messageMatch: false
        } as SearchListItemProps);
      }
    });

    return results;
  };

  const renderContent = () => {
    if (loading) return <LoadingSpinner />;
    else if (error) return <Alert severity='error'>{t('error.searchFailed')}</Alert>;

    const filteredResults = getFilteredResults();

    return (
      <TransitionGroup
        in={filteredResults.length > 0}
        className={`${filteredResults.length > 0 ? classes.root : ''}`}
      >
        <ul className='list-container' data-testid='search-results-list'>
          {filteredResults.map((result: SearchListItemProps, i: number) => {
            return <SearchListItem key={`search-item-${i}`} {...result} />;
          })}
        </ul>
      </TransitionGroup>
    );
  };

  return (
    <div className={classes.container} data-testid='search'>
      <SearchField value={searchInput} onFocus={onFocus} onInput={onInput} onBlur={onBlur} />
      {renderContent()}
    </div>
  );
};

export default Search;
