import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { BORDER_INPUT } from '../../styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      marginBottom: '15px'
    },
    editor: {
      '& .ql-toolbar': {
        border: 'none !important'
      },

      '& .ql-container': {
        border: BORDER_INPUT,
        borderTop: `${BORDER_INPUT} !important`,
        borderRadius: '8px',
        backgroundColor: 'white'
      },

      '& .ql-editor': {
        minHeight: '90px',
        maxHeight: '400px',
        color: theme.palette.text.primary,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: '16px',
        paddingRight: '40px',
        resize: 'vertical',

        '& p, span': {
          fontFamily: 'inherit !important',
          fontWeight: 'inherit !important',
          color: 'inherit !important',
          textDecoration: 'none !important'
        },

        '& ul, ol': {
          padding: 0
        }
      },

      '& .ql-editing': {
        zIndex: 1
      },

      '& button:hover, .ql-active': {
        '& path, line, rect': {
          stroke: `${theme.palette.primary.main} !important`
        }
      },

      '& .ql-blank:before': {
        fontStyle: 'normal',
        color: theme.palette.text.disabled
      },

      '&.error > .ql-container': {
        border: '1px solid red !important'
      }
    },
    errorText: {
      position: 'absolute',
      margin: '3px 14px 0'
    },
    viewer: {
      fontFamily: theme.typography.fontFamily,
      fontSize: '.9rem',
      wordBreak: 'break-word',

      '& a': {
        color: theme.palette.primary.main,
        textDecoration: 'none',

        '&:hover': {
          textDecoration: 'underline'
        }
      },

      '& ul, ol': {
        padding: 0,
        margin: '5px 0 5px 25px'
      },

      '& li': {
        fontSize: '1rem'
      },

      '&.latest': {
        '& ul, ol': {
          listStyle: 'none',
          margin: 0,
          padding: 0
        }
      }
    },
    attachmentContainer: {
      position: 'absolute',
      top: '-4px',
      left: '115px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: 'calc(100% - 115px)'
    },
    attachmentIcon: {
      color: theme.palette.text.primary,
      background: 'none !important',
      '&:hover': {
        color: theme.palette.primary.main
      }
    },

    attachmentName: {
      width: 'calc(100% - 10px)',
      marginLeft: '10px',
      color: theme.palette.grey[600],
      fontSize: '.9rem'
    }
  })
);
