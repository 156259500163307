import { ConversationStatusEnum } from '../graphql/generated/graphql';

export interface ConversationStatus {
  type: ConversationStatusEnum | string;
  label: string;
}

export const allStatuses: ConversationStatus[] = [
  {
    type: ConversationStatusEnum.Open,
    label: 'filterOpen'
  },
  {
    type: ConversationStatusEnum.Closed,
    label: 'filterClosed'
  },
  {
    type: ConversationStatusEnum.Archived,
    label: 'filterArchived'
  },
  {
    type: ConversationStatusEnum.Deleted,
    label: 'filterDeleted'
  }
];

export const basicStatuses: ConversationStatus[] = [allStatuses[0], allStatuses[1]];
