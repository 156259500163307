import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select
} from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FormInputState } from '../../hooks/useForm';
import styles from './styles';

export type SelectorItem = {
  label: string;
  key: string;
  value: string;
};

interface SelectorProps {
  id: string;
  state: FormInputState;
  items: SelectorItem[];
  disabled?: boolean;
  onChange: (_event: React.ChangeEvent<{ value: unknown }>) => void;
}

const Selector: React.FC<SelectorProps> = ({ id, state, items, disabled, onChange }) => {
  const classes = styles();
  const { t } = useTranslation();

  return (
    <FormControl
      variant='outlined'
      className={classes.formControl}
      required={state.mandatory}
      error={state.error}
    >
      <InputLabel shrink={true} id={`selector-label-${id}`}>
        {t(id)}
      </InputLabel>
      <Select
        id={`selector-item-${id}`}
        className={`${disabled ? 'disabled' : ''} input`}
        name={id}
        labelId={`selector-label-${id}`}
        input={<OutlinedInput notched label={t(id)} />}
        inputProps={{ 'data-testid': `selector-${id}` }}
        value={state.value}
        onChange={onChange}
        label={t(id)}
        disabled={disabled}
      >
        {items.map((item: SelectorItem) => (
          <MenuItem className='messaging-menu-item' key={item.key} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {state.error && <FormHelperText>{t(`${state.errorMessage}`)}</FormHelperText>}
    </FormControl>
  );
};

export default Selector;
