import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { MOBILE_BREAKPOINT } from '../../../../styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#FCFCFD'
    },
    header: {
      backgroundColor: 'white',
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        height: '4rem !important',

        '& .header-content': {
          height: '4rem !important'
        }
      }
    },
    content: {
      padding: '20px'
    },
    heading: {
      fontSize: '1.2rem',
      lineHeight: '3.2rem'
    },
    form: {
      '& .ql-toolbar': {
        marginTop: '1rem'
      },
      marginTop: '-20px'
    },
    errorContainer: {
      margin: '10px 0 20px'
    },
    formElement: {
      display: 'block',

      '&:not(:first-of-type)': {
        marginTop: '1.5rem'
      }
    },
    createButton: {
      marginBottom: '1rem',
      float: 'right'
    }
  })
);
