import { Link, MenuItem } from '@material-ui/core';
import * as React from 'react';
import { AppContext } from '../../../../context/app/AppContext';
import { ConversationStatusEnum, UserRightsType } from '../../../../graphql/generated/graphql';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import { allStatuses, basicStatuses } from '../../../../utils/statuses';

const StatusFilters = React.forwardRef(() => {
  const { appContext, dispatch } = React.useContext(AppContext);
  const { t } = useTranslation();
  const statuses = appContext.userRights?.includes(UserRightsType.MessagesUpdateStatus)
    ? allStatuses
    : basicStatuses;
  const classes = styles();

  const onFilterClicked = (event: React.MouseEvent, status: ConversationStatusEnum) => {
    event.preventDefault();

    dispatch({
      ...appContext,
      searchActive: false,
      filters: { conversationStatus: status },
      selectedApartmentIds: appContext.apartments.map((apartment) => apartment.id),
      activeConversation: undefined
    });
  };

  return (
    <>
      {statuses.map((s, i) => {
        const active = s.type === appContext.filters.conversationStatus;

        return (
          <MenuItem
            data-testid={`status-${s.type}`}
            key={`status-${i}`}
            className={`messaging-menu-item filter ${active && 'active'}`}
          >
            <Link
              href='#'
              className={classes.link}
              color={active ? 'primary' : 'inherit'}
              onClick={(e: React.MouseEvent) => {
                onFilterClicked(e, s.type as ConversationStatusEnum);
              }}
            >
              {t(s.label)}
            </Link>
          </MenuItem>
        );
      })}
    </>
  );
});

StatusFilters.displayName = 'StatusFilters';
export default StatusFilters;
