import { Alert } from '@material-ui/lab';
import React, { FC, useContext, useState } from 'react';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import globalStyles from '../../styles';
import styles from './styles';
import { getErrorText } from '../../utils/errors';
import ConversationColumn from './Conversation';
import FiltersColumn from './Filters';
import ListingColumn from './Listing';
import {
  ConversationCategory,
  useConversationCategoriesLazyQuery,
  UserRightsType
} from '../../graphql/generated/graphql';
import { AppContext } from '../../context/app/AppContext';
import AppHeader from '../../components/AppHeader/AppHeader';
import { t } from 'i18next';

const Messaging: FC = () => {
  const { appContext, dispatch } = useContext(AppContext);
  const [fetchStarted, setFetchStarted] = useState(false);
  const classes = styles();
  const globalClasses = globalStyles();

  const [getCategories, { data, error, loading }] = useConversationCategoriesLazyQuery({
    onCompleted: () => {
      dispatch({
        ...appContext,
        initialized: true,
        categories: data?.conversationCategories as ConversationCategory[]
      });
    }
  });

  const renderContent = () => {
    if (!appContext.userRights?.includes(UserRightsType.MessagesRead)) {
      return <Alert severity='error'>{t('error.noUserRightsMessages')}</Alert>;
    } else if (!fetchStarted) {
      setFetchStarted(true);
      getCategories();
      return null;
    } else if (loading) {
      return <LoadingSpinner />;
    } else if (error) {
      return (
        <Alert severity='error' className={globalClasses.alert}>
          {getErrorText(error)}
        </Alert>
      );
    }

    return (
      <div className={classes.messaging} role='main'>
        <FiltersColumn />
        <ListingColumn />
        <ConversationColumn />
      </div>
    );
  };

  return (
    <>
      <AppHeader
        titleKey='apartmentMessages'
        newButtonKey={
          appContext.userRights?.includes(UserRightsType.MessagesCreate) ? 'newConversation' : null
        }
      />
      {renderContent()}
    </>
  );
};

export default Messaging;
