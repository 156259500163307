import { useMediaQuery, useTheme } from '@material-ui/core';
import React, { useContext } from 'react';
import { AppContext } from '../../../context/app/AppContext';
import { MOBILE_BREAKPOINT } from '../../../styles';
import ConversationList from './ConversationList';
import MobileFilters from './MobileFilters';
import Search from './Search';
import styles from './styles';

const ListingColumn: React.FC = () => {
  const { appContext } = useContext(AppContext);
  const classes = styles();
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down(MOBILE_BREAKPOINT));

  if (mobileSize && (appContext.activeConversation || appContext.createNew)) return null;

  return (
    <div className={`${classes.container} listing-column`} data-testid='listing-column'>
      <div className={classes.topContainer}>
        <Search />
        <MobileFilters />
      </div>
      {!appContext.searchActive && <ConversationList />}
    </div>
  );
};

export default ListingColumn;
