import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    emptyConversation: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      color: '#adadad',

      '& p': {
        fontSize: '2rem'
      }
    },
    infoIcon: {
      width: '5rem',
      height: '5rem',
      marginTop: '10px'
    }
  })
);
