import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative'
    },
    alertContainer: {
      margin: '-.3rem 1rem 0'
    }
  })
);
