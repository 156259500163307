import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { BORDER } from '../../../styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      borderRight: BORDER,

      '& .messaging-list-item': {
        '& .message': {
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '.2rem',
          color: theme.palette.text.hint,
          gap: '0.2rem',
          maxHeight: '1.2rem',
          overflow: 'hidden',

          '& > p': {
            fontSize: '.8rem'
          }
        },

        '& .date': {
          minWidth: '5rem',
          textAlign: 'right',
          marginTop: '1px'
        },

        '& .unread > span': {
          transform: 'scale(.6)'
        },

        '& .latest': {
          pointerEvents: 'none',

          '& > p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            height: '1.3rem'
          }
        }
      }
    },
    topContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  })
);
