import { AppContext } from '../../../../context/app/AppContext';
import {
  ConversationCategory,
  useCreateMessageMutation
} from '../../../../graphql/generated/graphql';
import { Alert } from '@material-ui/lab';
import styles from './styles';
import React, { useContext } from 'react';
import useForm, { FormState } from '../../../../hooks/useForm';
import { validateInputField } from '../../../../utils/validator';
import { ActionButton } from '../../../../components/ActionButton/ActionButton';
import Editor from '../../../../components/Wysiwyg/Editor';
import { useTranslation } from 'react-i18next';
import { AppContextProps } from '../../../../context/app/interfaces';
import SendIcon from '@material-ui/icons/Send';
import useNotificationEmail from '../../../../hooks/useNotificationEmail';

const NewMessageForm: React.FC = () => {
  const classes = styles();
  const { appContext, dispatch } = useContext(AppContext);
  const initialFormState: FormState = {
    message: { value: '', mandatory: true },
    attachment: { file: undefined }
  };
  const { t } = useTranslation();

  const submit = () => {
    const validatedInput = validateInputField({
      mandatory: true,
      value: formState.message.value
    });
    if (validatedInput.error) {
      setFormState({ ...formState, message: validatedInput });
      return;
    }

    const { message, attachment } = formState;

    submitForm({
      variables: {
        input: {
          conversationUuid: appContext.activeConversation?.conversationUuid || '',
          message: {
            content: message.value
          },
          notification: noticationEmailInput
        },
        attachment: attachment.file
      }
    });
  };

  const { formState, setFormState, handleFileChange, handleSubmit } = useForm(
    initialFormState,
    submit
  );
  const activeApartment = appContext.apartments.filter(
    (a) => a.id === appContext.activeConversation?.apartmentId
  )[0];
  const activeCategory = appContext.categories?.find(
    (category: ConversationCategory) =>
      category.uuid === appContext.activeConversation?.categoryUuid
  )?.name;
  const noticationEmailInput = useNotificationEmail({
    apartments: [activeApartment],
    category: String(activeCategory)
  });

  const [submitForm, { loading, error }] = useCreateMessageMutation({
    onCompleted: () => {
      const activeConversation = appContext.activeConversation;
      dispatch({
        ...appContext,
        searchActive: false,
        activeConversation: { ...activeConversation, messageUuid: undefined }
      } as AppContextProps);
      setFormState(initialFormState);
    }
  });

  const onEditorContentChanged = (content: string) => {
    setFormState({
      ...formState,
      message: {
        value: content,
        wysiwygContent: true,
        sanitationParameters: appContext.sanitationParameters
      }
    });
  };

  return (
    <div className={classes.container}>
      <form
        name='new-message-form'
        className={classes.form}
        onSubmit={(e) => {
          e.preventDefault();
        }}
        encType='multipart/form-data'
      >
        <Editor
          contentState={formState.message}
          fileState={formState.attachment}
          onChange={onEditorContentChanged}
          onFileChange={handleFileChange}
        />

        <ActionButton
          className={classes.sendButton}
          loading={loading}
          onClick={handleSubmit}
          icon={<SendIcon />}
        />

        {error && (
          <Alert className={classes.errorContainer} severity='error' variant='outlined'>
            {t('error.messageSubmitFailed')}
          </Alert>
        )}
      </form>
    </div>
  );
};

export default NewMessageForm;
