import React, { FC } from 'react';
import styles from './styles';
import { Link, Typography } from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/Attachment';

interface MessageAttachmentProps {
  url: string;
  name: string;
  type: string;
}

const MessageAttachment: FC<MessageAttachmentProps> = ({ url, name, type }) => {
  const classes = styles();

  return (
    <div className={classes.attachmentContainer}>
      <Link className={`${classes.link}`} href={url} target='_blank'>
        {type.includes('image') && <img src={url} className={classes.image} alt={name} />}
        <div className={classes.linkTextContainer}>
          <AttachmentIcon />
          <Typography className='truncate'>{name}</Typography>
        </div>
      </Link>
    </div>
  );
};

export default MessageAttachment;
