import { Typography } from '@material-ui/core';
import React, { FC, useContext } from 'react';
import { AppContext } from '../../../../../context/app/AppContext';
import {
  BulletinApartmentInformation,
  BulletinMessage
} from '../../../../../graphql/generated/graphql';
import styles from './styles';
import Expander from '../../../../../components/Expander/Expander';

const BulletinHeader: FC = () => {
  const classes = styles();
  const { appContext } = useContext(AppContext);
  const { subject, apartmentInfo } = appContext.activeBulletin as BulletinMessage;

  let apartmentNames = '';
  apartmentInfo.forEach((b: BulletinApartmentInformation, i: number) => {
    const name = appContext.apartments.find((a) => b.apartmentId === a.id)?.name;
    apartmentNames += i > 0 ? ', ' + name : name;
  });

  return (
    <div className={classes.header} data-testid='bulletin-header'>
      <Typography className={classes.subject}>{subject}</Typography>
      <Expander>{apartmentNames}</Expander>
    </div>
  );
};

export default BulletinHeader;
