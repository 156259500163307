import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { BORDER } from '../../../styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    header: {
      padding: '10px 15px',
      borderBottom: BORDER,
      backgroundColor: 'white'
    },
    subject: {
      width: 'auto',
      fontSize: '1.1rem'
    },
    apartmentInfo: {
      color: theme.palette.primary.main,
      width: 'auto',
      display: 'inline-flex',
      fontSize: '.9rem',
      maxWidth: 'calc(100% - 50px)',
      transition: 'height .5s'
    },
    expanderButton: {
      position: 'absolute',
      top: '22px',
      right: '10px',

      '& svg': {
        transition: 'transform .3s'
      }
    }
  })
);
