import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Upload: any;
};

export type Apartment = {
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type Attachment = {
  __typename?: 'Attachment';
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  mimeType?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  uuid: Scalars['String'];
  uuidFilename: Scalars['String'];
};

export type BulletinApartmentInformation = {
  __typename?: 'BulletinApartmentInformation';
  acknowledgedAt?: Maybe<Scalars['String']>;
  acknowledgedBy?: Maybe<Scalars['String']>;
  apartmentId: Scalars['Int'];
};

export type BulletinMessage = {
  __typename?: 'BulletinMessage';
  apartmentInfo: Array<BulletinApartmentInformation>;
  attachment?: Maybe<Attachment>;
  content?: Maybe<Scalars['String']>;
  messageStatus?: Maybe<MessageStatusEnum>;
  requiresAcknowledgement?: Maybe<Scalars['Boolean']>;
  sentDate: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  uuid: Scalars['String'];
};

export type BulletinMessageFilters = {
  messageStatus: MessageStatusEnum;
};

export type Conversation = {
  __typename?: 'Conversation';
  apartmentId?: Maybe<Scalars['Int']>;
  categoryUuid?: Maybe<Scalars['String']>;
  conversationStatus?: Maybe<Scalars['String']>;
  isUnread?: Maybe<Scalars['Boolean']>;
  latestMessage?: Maybe<Message>;
  subject?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

export type ConversationCategory = {
  __typename?: 'ConversationCategory';
  name?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

export type ConversationFilters = {
  conversationStatus: ConversationStatusEnum;
};

export type ConversationResponse = {
  __typename?: 'ConversationResponse';
  conversation: Conversation;
  messages: Array<Maybe<Message>>;
};

export enum ConversationStatusEnum {
  Archived = 'ARCHIVED',
  Closed = 'CLOSED',
  Deleted = 'DELETED',
  Open = 'OPEN'
}

export type CreateBulletinMessageInput = {
  apartmentIds: Array<Scalars['Int']>;
  content: Scalars['String'];
  notification?: InputMaybe<NotificationEmailInput>;
  requiresAcknowledgement: Scalars['Boolean'];
  subject: Scalars['String'];
};

export type CreateBulletinMessageResponse = {
  __typename?: 'CreateBulletinMessageResponse';
  uuid: Scalars['String'];
};

export type CreateConversationInput = {
  apartmentId: Scalars['Int'];
  category: Scalars['String'];
  message: MessageInput;
  notification?: InputMaybe<NotificationEmailInput>;
  subject: Scalars['String'];
};

export type CreateConversationResponse = {
  __typename?: 'CreateConversationResponse';
  conversationUuid: Scalars['String'];
  messageUuid: Scalars['String'];
};

export type CreateMessageInput = {
  conversationUuid: Scalars['String'];
  message: MessageInput;
  notification?: InputMaybe<NotificationEmailInput>;
};

export type CreateMessageResponse = {
  __typename?: 'CreateMessageResponse';
  uuid: Scalars['String'];
};

export type DeleteConversationInput = {
  conversationUuid: Scalars['String'];
};

export type DeleteConversationResponse = {
  __typename?: 'DeleteConversationResponse';
  status: Scalars['String'];
};

export type DeleteMessageInput = {
  conversationUuid: Scalars['String'];
  messageUuid: Scalars['String'];
};

export type DeleteMessageResponse = {
  __typename?: 'DeleteMessageResponse';
  status: Scalars['String'];
};

export type MarkConversationAsReadInput = {
  conversationUuid: Scalars['String'];
};

export type MarkConversationAsReadResponse = {
  __typename?: 'MarkConversationAsReadResponse';
  status: Scalars['String'];
};

export type Message = {
  __typename?: 'Message';
  attachment?: Maybe<Attachment>;
  content?: Maybe<Scalars['String']>;
  conversationUuid: Scalars['String'];
  deliveryStatus?: Maybe<Scalars['String']>;
  sentDate: Scalars['String'];
  user?: Maybe<User>;
  uuid: Scalars['String'];
};

export type MessageInput = {
  content?: InputMaybe<Scalars['String']>;
  conversationUuid?: InputMaybe<Scalars['String']>;
};

export enum MessageStatusEnum {
  Archived = 'ARCHIVED',
  Closed = 'CLOSED',
  Deleted = 'DELETED',
  Open = 'OPEN'
}

export enum MessageType {
  ApartmentMessage = 'APARTMENT_MESSAGE',
  Bulletins = 'BULLETINS'
}

export type Mutation = {
  __typename?: 'Mutation';
  createBulletinMessage: CreateBulletinMessageResponse;
  createConversation: CreateConversationResponse;
  createMessage: CreateMessageResponse;
  deleteConversation: DeleteConversationResponse;
  deleteMessage: DeleteMessageResponse;
  markConversationAsRead: MarkConversationAsReadResponse;
  setConversationStatus: SetConversationStatusResponse;
};


export type MutationCreateBulletinMessageArgs = {
  attachment?: InputMaybe<Scalars['Upload']>;
  input: CreateBulletinMessageInput;
};


export type MutationCreateConversationArgs = {
  attachment?: InputMaybe<Scalars['Upload']>;
  input: CreateConversationInput;
};


export type MutationCreateMessageArgs = {
  attachment?: InputMaybe<Scalars['Upload']>;
  input: CreateMessageInput;
};


export type MutationDeleteConversationArgs = {
  input: DeleteConversationInput;
};


export type MutationDeleteMessageArgs = {
  input: DeleteMessageInput;
};


export type MutationMarkConversationAsReadArgs = {
  input: MarkConversationAsReadInput;
};


export type MutationSetConversationStatusArgs = {
  input: SetConversationStatusInput;
};

export type NewMessage = {
  __typename?: 'NewMessage';
  conversation?: Maybe<Conversation>;
  message?: Maybe<Message>;
};

export type NotificationEmailInput = {
  apartments: Array<Apartment>;
  category?: InputMaybe<Scalars['String']>;
  projectName: Scalars['String'];
  subject: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  bulletinMessages?: Maybe<Array<Maybe<BulletinMessage>>>;
  conversation?: Maybe<ConversationResponse>;
  conversationCategories?: Maybe<Array<Maybe<ConversationCategory>>>;
  conversations?: Maybe<Array<Maybe<Conversation>>>;
  conversationsAndMessages?: Maybe<Array<Maybe<ConversationResponse>>>;
  messages?: Maybe<Array<Maybe<Message>>>;
  settings?: Maybe<Settings>;
  userRights?: Maybe<Array<Maybe<UserRightsType>>>;
};


export type QueryBulletinMessagesArgs = {
  apartmentIds: Array<Scalars['Int']>;
  filters: BulletinMessageFilters;
};


export type QueryConversationArgs = {
  conversationUuid: Scalars['String'];
};


export type QueryConversationsArgs = {
  apartmentIds: Array<Scalars['Int']>;
  filters: ConversationFilters;
};


export type QueryConversationsAndMessagesArgs = {
  apartmentIds: Array<Scalars['Int']>;
  filters: ConversationFilters;
};


export type QueryMessagesArgs = {
  conversationUuid: Scalars['String'];
};


export type QuerySettingsArgs = {
  apartmentIds: Array<Scalars['Int']>;
};


export type QueryUserRightsArgs = {
  apartmentIds: Array<Scalars['Int']>;
};

export type SanitationParameters = {
  __typename?: 'SanitationParameters';
  allowedAttributes: Array<Scalars['String']>;
  allowedTags: Array<Scalars['String']>;
};

export type SetConversationStatusInput = {
  conversationUuid: Scalars['String'];
  status: ConversationStatusEnum;
};

export type SetConversationStatusResponse = {
  __typename?: 'SetConversationStatusResponse';
  status: Scalars['String'];
};

export type Settings = {
  __typename?: 'Settings';
  sanitationParameters?: Maybe<SanitationParameters>;
  userRights?: Maybe<Array<Maybe<UserRightsType>>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  messageCreated?: Maybe<NewMessage>;
};


export type SubscriptionMessageCreatedArgs = {
  apartmentId: Scalars['Int'];
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Scalars['String']>;
  isYou?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export enum UserRightsType {
  BulletinsCreate = 'BULLETINS_CREATE',
  BulletinsRead = 'BULLETINS_READ',
  MessagesCreate = 'MESSAGES_CREATE',
  MessagesRead = 'MESSAGES_READ',
  MessagesUpdateStatus = 'MESSAGES_UPDATE_STATUS'
}

export type CreateBulletinMessageMutationVariables = Exact<{
  input: CreateBulletinMessageInput;
  attachment?: InputMaybe<Scalars['Upload']>;
}>;


export type CreateBulletinMessageMutation = { __typename?: 'Mutation', createBulletinMessage: { __typename?: 'CreateBulletinMessageResponse', uuid: string } };

export type CreateConversationMutationVariables = Exact<{
  input: CreateConversationInput;
  attachment?: InputMaybe<Scalars['Upload']>;
}>;


export type CreateConversationMutation = { __typename?: 'Mutation', createConversation: { __typename?: 'CreateConversationResponse', messageUuid: string, conversationUuid: string } };

export type CreateMessageMutationVariables = Exact<{
  input: CreateMessageInput;
  attachment?: InputMaybe<Scalars['Upload']>;
}>;


export type CreateMessageMutation = { __typename?: 'Mutation', createMessage: { __typename?: 'CreateMessageResponse', uuid: string } };

export type SetConversationStatusMutationVariables = Exact<{
  input: SetConversationStatusInput;
}>;


export type SetConversationStatusMutation = { __typename?: 'Mutation', setConversationStatus: { __typename?: 'SetConversationStatusResponse', status: string } };

export type BulletinMessagesQueryVariables = Exact<{
  apartmentIds: Array<Scalars['Int']> | Scalars['Int'];
  filters: BulletinMessageFilters;
}>;


export type BulletinMessagesQuery = { __typename?: 'Query', bulletinMessages?: Array<{ __typename?: 'BulletinMessage', uuid: string, sentDate: string, subject?: string | null, content?: string | null, messageStatus?: MessageStatusEnum | null, requiresAcknowledgement?: boolean | null, attachment?: { __typename?: 'Attachment', uuid: string, url: string, filename: string, uuidFilename: string, mimeType?: string | null, createdBy?: string | null, createdAt?: string | null } | null, user?: { __typename?: 'User', isYou?: boolean | null, name?: string | null, avatar?: string | null } | null, apartmentInfo: Array<{ __typename?: 'BulletinApartmentInformation', apartmentId: number, acknowledgedBy?: string | null, acknowledgedAt?: string | null }> } | null> | null };

export type MessagesQueryVariables = Exact<{
  conversationUuid: Scalars['String'];
}>;


export type MessagesQuery = { __typename?: 'Query', conversation?: { __typename?: 'ConversationResponse', conversation: { __typename?: 'Conversation', uuid?: string | null, apartmentId?: number | null, subject?: string | null, isUnread?: boolean | null, latestMessage?: { __typename?: 'Message', uuid: string, conversationUuid: string, sentDate: string, content?: string | null, deliveryStatus?: string | null, user?: { __typename?: 'User', isYou?: boolean | null, name?: string | null, avatar?: string | null } | null } | null }, messages: Array<{ __typename?: 'Message', uuid: string, conversationUuid: string, sentDate: string, content?: string | null, deliveryStatus?: string | null, attachment?: { __typename?: 'Attachment', uuid: string, url: string, filename: string, uuidFilename: string, mimeType?: string | null } | null, user?: { __typename?: 'User', isYou?: boolean | null, name?: string | null, avatar?: string | null } | null } | null> } | null };

export type ConversationCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type ConversationCategoriesQuery = { __typename?: 'Query', conversationCategories?: Array<{ __typename?: 'ConversationCategory', uuid?: string | null, name?: string | null } | null> | null };

export type ConversationsQueryVariables = Exact<{
  apartmentIds: Array<Scalars['Int']> | Scalars['Int'];
  filters: ConversationFilters;
}>;


export type ConversationsQuery = { __typename?: 'Query', conversations?: Array<{ __typename?: 'Conversation', uuid?: string | null, apartmentId?: number | null, subject?: string | null, isUnread?: boolean | null, categoryUuid?: string | null, conversationStatus?: string | null, latestMessage?: { __typename?: 'Message', uuid: string, conversationUuid: string, sentDate: string, content?: string | null, deliveryStatus?: string | null, user?: { __typename?: 'User', isYou?: boolean | null, name?: string | null, avatar?: string | null } | null } | null } | null> | null };

export type ConversationsAndMessagesQueryVariables = Exact<{
  apartmentIds: Array<Scalars['Int']> | Scalars['Int'];
  filters: ConversationFilters;
}>;


export type ConversationsAndMessagesQuery = { __typename?: 'Query', conversationsAndMessages?: Array<{ __typename?: 'ConversationResponse', conversation: { __typename?: 'Conversation', uuid?: string | null, apartmentId?: number | null, subject?: string | null, categoryUuid?: string | null, conversationStatus?: string | null }, messages: Array<{ __typename?: 'Message', uuid: string, sentDate: string, content?: string | null, user?: { __typename?: 'User', isYou?: boolean | null, name?: string | null, avatar?: string | null } | null } | null> } | null> | null };

export type SettingsQueryVariables = Exact<{
  apartmentIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type SettingsQuery = { __typename?: 'Query', settings?: { __typename?: 'Settings', userRights?: Array<UserRightsType | null> | null, sanitationParameters?: { __typename?: 'SanitationParameters', allowedAttributes: Array<string>, allowedTags: Array<string> } | null } | null };

export type UserRightsQueryVariables = Exact<{
  apartmentIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type UserRightsQuery = { __typename?: 'Query', userRights?: Array<UserRightsType | null> | null };

export type MessageCreatedSubscriptionVariables = Exact<{
  apartmentId: Scalars['Int'];
}>;


export type MessageCreatedSubscription = { __typename?: 'Subscription', messageCreated?: { __typename?: 'NewMessage', conversation?: { __typename?: 'Conversation', uuid?: string | null, apartmentId?: number | null, subject?: string | null, isUnread?: boolean | null, categoryUuid?: string | null, conversationStatus?: string | null, latestMessage?: { __typename?: 'Message', uuid: string, conversationUuid: string, sentDate: string, content?: string | null, deliveryStatus?: string | null, user?: { __typename?: 'User', isYou?: boolean | null, name?: string | null, avatar?: string | null } | null } | null } | null, message?: { __typename?: 'Message', uuid: string, conversationUuid: string, sentDate: string, content?: string | null, deliveryStatus?: string | null, user?: { __typename?: 'User', isYou?: boolean | null, name?: string | null, avatar?: string | null } | null } | null } | null };


export const CreateBulletinMessageDocument = gql`
    mutation CreateBulletinMessage($input: CreateBulletinMessageInput!, $attachment: Upload) {
  createBulletinMessage(input: $input, attachment: $attachment) {
    uuid
  }
}
    `;
export type CreateBulletinMessageMutationFn = Apollo.MutationFunction<CreateBulletinMessageMutation, CreateBulletinMessageMutationVariables>;

/**
 * __useCreateBulletinMessageMutation__
 *
 * To run a mutation, you first call `useCreateBulletinMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBulletinMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBulletinMessageMutation, { data, loading, error }] = useCreateBulletinMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *      attachment: // value for 'attachment'
 *   },
 * });
 */
export function useCreateBulletinMessageMutation(baseOptions?: Apollo.MutationHookOptions<CreateBulletinMessageMutation, CreateBulletinMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBulletinMessageMutation, CreateBulletinMessageMutationVariables>(CreateBulletinMessageDocument, options);
      }
export type CreateBulletinMessageMutationHookResult = ReturnType<typeof useCreateBulletinMessageMutation>;
export type CreateBulletinMessageMutationResult = Apollo.MutationResult<CreateBulletinMessageMutation>;
export type CreateBulletinMessageMutationOptions = Apollo.BaseMutationOptions<CreateBulletinMessageMutation, CreateBulletinMessageMutationVariables>;
export const CreateConversationDocument = gql`
    mutation CreateConversation($input: CreateConversationInput!, $attachment: Upload) {
  createConversation(input: $input, attachment: $attachment) {
    messageUuid
    conversationUuid
  }
}
    `;
export type CreateConversationMutationFn = Apollo.MutationFunction<CreateConversationMutation, CreateConversationMutationVariables>;

/**
 * __useCreateConversationMutation__
 *
 * To run a mutation, you first call `useCreateConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConversationMutation, { data, loading, error }] = useCreateConversationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      attachment: // value for 'attachment'
 *   },
 * });
 */
export function useCreateConversationMutation(baseOptions?: Apollo.MutationHookOptions<CreateConversationMutation, CreateConversationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateConversationMutation, CreateConversationMutationVariables>(CreateConversationDocument, options);
      }
export type CreateConversationMutationHookResult = ReturnType<typeof useCreateConversationMutation>;
export type CreateConversationMutationResult = Apollo.MutationResult<CreateConversationMutation>;
export type CreateConversationMutationOptions = Apollo.BaseMutationOptions<CreateConversationMutation, CreateConversationMutationVariables>;
export const CreateMessageDocument = gql`
    mutation CreateMessage($input: CreateMessageInput!, $attachment: Upload) {
  createMessage(input: $input, attachment: $attachment) {
    uuid
  }
}
    `;
export type CreateMessageMutationFn = Apollo.MutationFunction<CreateMessageMutation, CreateMessageMutationVariables>;

/**
 * __useCreateMessageMutation__
 *
 * To run a mutation, you first call `useCreateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageMutation, { data, loading, error }] = useCreateMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *      attachment: // value for 'attachment'
 *   },
 * });
 */
export function useCreateMessageMutation(baseOptions?: Apollo.MutationHookOptions<CreateMessageMutation, CreateMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMessageMutation, CreateMessageMutationVariables>(CreateMessageDocument, options);
      }
export type CreateMessageMutationHookResult = ReturnType<typeof useCreateMessageMutation>;
export type CreateMessageMutationResult = Apollo.MutationResult<CreateMessageMutation>;
export type CreateMessageMutationOptions = Apollo.BaseMutationOptions<CreateMessageMutation, CreateMessageMutationVariables>;
export const SetConversationStatusDocument = gql`
    mutation SetConversationStatus($input: SetConversationStatusInput!) {
  setConversationStatus(input: $input) {
    status
  }
}
    `;
export type SetConversationStatusMutationFn = Apollo.MutationFunction<SetConversationStatusMutation, SetConversationStatusMutationVariables>;

/**
 * __useSetConversationStatusMutation__
 *
 * To run a mutation, you first call `useSetConversationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetConversationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setConversationStatusMutation, { data, loading, error }] = useSetConversationStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetConversationStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetConversationStatusMutation, SetConversationStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetConversationStatusMutation, SetConversationStatusMutationVariables>(SetConversationStatusDocument, options);
      }
export type SetConversationStatusMutationHookResult = ReturnType<typeof useSetConversationStatusMutation>;
export type SetConversationStatusMutationResult = Apollo.MutationResult<SetConversationStatusMutation>;
export type SetConversationStatusMutationOptions = Apollo.BaseMutationOptions<SetConversationStatusMutation, SetConversationStatusMutationVariables>;
export const BulletinMessagesDocument = gql`
    query BulletinMessages($apartmentIds: [Int!]!, $filters: BulletinMessageFilters!) {
  bulletinMessages(apartmentIds: $apartmentIds, filters: $filters) {
    uuid
    sentDate
    subject
    content
    attachment {
      uuid
      url
      filename
      uuidFilename
      mimeType
      createdBy
      createdAt
    }
    user {
      isYou
      name
      avatar
    }
    messageStatus
    requiresAcknowledgement
    apartmentInfo {
      apartmentId
      acknowledgedBy
      acknowledgedAt
    }
  }
}
    `;

/**
 * __useBulletinMessagesQuery__
 *
 * To run a query within a React component, call `useBulletinMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulletinMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulletinMessagesQuery({
 *   variables: {
 *      apartmentIds: // value for 'apartmentIds'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useBulletinMessagesQuery(baseOptions: Apollo.QueryHookOptions<BulletinMessagesQuery, BulletinMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BulletinMessagesQuery, BulletinMessagesQueryVariables>(BulletinMessagesDocument, options);
      }
export function useBulletinMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BulletinMessagesQuery, BulletinMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BulletinMessagesQuery, BulletinMessagesQueryVariables>(BulletinMessagesDocument, options);
        }
export type BulletinMessagesQueryHookResult = ReturnType<typeof useBulletinMessagesQuery>;
export type BulletinMessagesLazyQueryHookResult = ReturnType<typeof useBulletinMessagesLazyQuery>;
export type BulletinMessagesQueryResult = Apollo.QueryResult<BulletinMessagesQuery, BulletinMessagesQueryVariables>;
export const MessagesDocument = gql`
    query Messages($conversationUuid: String!) {
  conversation(conversationUuid: $conversationUuid) {
    conversation {
      uuid
      apartmentId
      subject
      latestMessage {
        uuid
        conversationUuid
        sentDate
        content
        deliveryStatus
        user {
          isYou
          name
          avatar
        }
      }
      isUnread
    }
    messages {
      uuid
      conversationUuid
      sentDate
      content
      deliveryStatus
      attachment {
        uuid
        url
        filename
        uuidFilename
        mimeType
      }
      user {
        isYou
        name
        avatar
      }
    }
  }
}
    `;

/**
 * __useMessagesQuery__
 *
 * To run a query within a React component, call `useMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagesQuery({
 *   variables: {
 *      conversationUuid: // value for 'conversationUuid'
 *   },
 * });
 */
export function useMessagesQuery(baseOptions: Apollo.QueryHookOptions<MessagesQuery, MessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessagesQuery, MessagesQueryVariables>(MessagesDocument, options);
      }
export function useMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessagesQuery, MessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessagesQuery, MessagesQueryVariables>(MessagesDocument, options);
        }
export type MessagesQueryHookResult = ReturnType<typeof useMessagesQuery>;
export type MessagesLazyQueryHookResult = ReturnType<typeof useMessagesLazyQuery>;
export type MessagesQueryResult = Apollo.QueryResult<MessagesQuery, MessagesQueryVariables>;
export const ConversationCategoriesDocument = gql`
    query ConversationCategories {
  conversationCategories {
    uuid
    name
  }
}
    `;

/**
 * __useConversationCategoriesQuery__
 *
 * To run a query within a React component, call `useConversationCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useConversationCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<ConversationCategoriesQuery, ConversationCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConversationCategoriesQuery, ConversationCategoriesQueryVariables>(ConversationCategoriesDocument, options);
      }
export function useConversationCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConversationCategoriesQuery, ConversationCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConversationCategoriesQuery, ConversationCategoriesQueryVariables>(ConversationCategoriesDocument, options);
        }
export type ConversationCategoriesQueryHookResult = ReturnType<typeof useConversationCategoriesQuery>;
export type ConversationCategoriesLazyQueryHookResult = ReturnType<typeof useConversationCategoriesLazyQuery>;
export type ConversationCategoriesQueryResult = Apollo.QueryResult<ConversationCategoriesQuery, ConversationCategoriesQueryVariables>;
export const ConversationsDocument = gql`
    query Conversations($apartmentIds: [Int!]!, $filters: ConversationFilters!) {
  conversations(apartmentIds: $apartmentIds, filters: $filters) {
    uuid
    apartmentId
    subject
    latestMessage {
      uuid
      conversationUuid
      sentDate
      content
      deliveryStatus
      user {
        isYou
        name
        avatar
      }
    }
    isUnread
    categoryUuid
    conversationStatus
  }
}
    `;

/**
 * __useConversationsQuery__
 *
 * To run a query within a React component, call `useConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationsQuery({
 *   variables: {
 *      apartmentIds: // value for 'apartmentIds'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useConversationsQuery(baseOptions: Apollo.QueryHookOptions<ConversationsQuery, ConversationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConversationsQuery, ConversationsQueryVariables>(ConversationsDocument, options);
      }
export function useConversationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConversationsQuery, ConversationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConversationsQuery, ConversationsQueryVariables>(ConversationsDocument, options);
        }
export type ConversationsQueryHookResult = ReturnType<typeof useConversationsQuery>;
export type ConversationsLazyQueryHookResult = ReturnType<typeof useConversationsLazyQuery>;
export type ConversationsQueryResult = Apollo.QueryResult<ConversationsQuery, ConversationsQueryVariables>;
export const ConversationsAndMessagesDocument = gql`
    query ConversationsAndMessages($apartmentIds: [Int!]!, $filters: ConversationFilters!) {
  conversationsAndMessages(apartmentIds: $apartmentIds, filters: $filters) {
    conversation {
      uuid
      apartmentId
      subject
      categoryUuid
      conversationStatus
    }
    messages {
      uuid
      sentDate
      content
      user {
        isYou
        name
        avatar
      }
    }
  }
}
    `;

/**
 * __useConversationsAndMessagesQuery__
 *
 * To run a query within a React component, call `useConversationsAndMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationsAndMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationsAndMessagesQuery({
 *   variables: {
 *      apartmentIds: // value for 'apartmentIds'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useConversationsAndMessagesQuery(baseOptions: Apollo.QueryHookOptions<ConversationsAndMessagesQuery, ConversationsAndMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConversationsAndMessagesQuery, ConversationsAndMessagesQueryVariables>(ConversationsAndMessagesDocument, options);
      }
export function useConversationsAndMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConversationsAndMessagesQuery, ConversationsAndMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConversationsAndMessagesQuery, ConversationsAndMessagesQueryVariables>(ConversationsAndMessagesDocument, options);
        }
export type ConversationsAndMessagesQueryHookResult = ReturnType<typeof useConversationsAndMessagesQuery>;
export type ConversationsAndMessagesLazyQueryHookResult = ReturnType<typeof useConversationsAndMessagesLazyQuery>;
export type ConversationsAndMessagesQueryResult = Apollo.QueryResult<ConversationsAndMessagesQuery, ConversationsAndMessagesQueryVariables>;
export const SettingsDocument = gql`
    query Settings($apartmentIds: [Int!]!) {
  settings(apartmentIds: $apartmentIds) {
    sanitationParameters {
      allowedAttributes
      allowedTags
    }
    userRights
  }
}
    `;

/**
 * __useSettingsQuery__
 *
 * To run a query within a React component, call `useSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsQuery({
 *   variables: {
 *      apartmentIds: // value for 'apartmentIds'
 *   },
 * });
 */
export function useSettingsQuery(baseOptions: Apollo.QueryHookOptions<SettingsQuery, SettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, options);
      }
export function useSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingsQuery, SettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, options);
        }
export type SettingsQueryHookResult = ReturnType<typeof useSettingsQuery>;
export type SettingsLazyQueryHookResult = ReturnType<typeof useSettingsLazyQuery>;
export type SettingsQueryResult = Apollo.QueryResult<SettingsQuery, SettingsQueryVariables>;
export const UserRightsDocument = gql`
    query UserRights($apartmentIds: [Int!]!) {
  userRights(apartmentIds: $apartmentIds)
}
    `;

/**
 * __useUserRightsQuery__
 *
 * To run a query within a React component, call `useUserRightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRightsQuery({
 *   variables: {
 *      apartmentIds: // value for 'apartmentIds'
 *   },
 * });
 */
export function useUserRightsQuery(baseOptions: Apollo.QueryHookOptions<UserRightsQuery, UserRightsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserRightsQuery, UserRightsQueryVariables>(UserRightsDocument, options);
      }
export function useUserRightsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserRightsQuery, UserRightsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserRightsQuery, UserRightsQueryVariables>(UserRightsDocument, options);
        }
export type UserRightsQueryHookResult = ReturnType<typeof useUserRightsQuery>;
export type UserRightsLazyQueryHookResult = ReturnType<typeof useUserRightsLazyQuery>;
export type UserRightsQueryResult = Apollo.QueryResult<UserRightsQuery, UserRightsQueryVariables>;
export const MessageCreatedDocument = gql`
    subscription MessageCreated($apartmentId: Int!) {
  messageCreated(apartmentId: $apartmentId) {
    conversation {
      uuid
      apartmentId
      subject
      latestMessage {
        uuid
        conversationUuid
        sentDate
        content
        deliveryStatus
        user {
          isYou
          name
          avatar
        }
      }
      isUnread
      categoryUuid
      conversationStatus
    }
    message {
      uuid
      conversationUuid
      sentDate
      content
      deliveryStatus
      user {
        isYou
        name
        avatar
      }
    }
  }
}
    `;

/**
 * __useMessageCreatedSubscription__
 *
 * To run a query within a React component, call `useMessageCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMessageCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageCreatedSubscription({
 *   variables: {
 *      apartmentId: // value for 'apartmentId'
 *   },
 * });
 */
export function useMessageCreatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<MessageCreatedSubscription, MessageCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<MessageCreatedSubscription, MessageCreatedSubscriptionVariables>(MessageCreatedDocument, options);
      }
export type MessageCreatedSubscriptionHookResult = ReturnType<typeof useMessageCreatedSubscription>;
export type MessageCreatedSubscriptionResult = Apollo.SubscriptionResult<MessageCreatedSubscription>;