import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const MOBILE_BREAKPOINT = 900;
export const BACKGROUND_COLOR = '#f9f9f9';
export const HIGHLIGHT_COLOR = '#ececec';
export const BORDER = '1px solid rgb(0 0 0 / 10%)';
export const BORDER_INPUT = '1px solid rgb(0 0 0 / 20%)';
export const CONTENT_HEIGHT = '775px';
export const HEADER_HEIGHT = '80px';

export default makeStyles((theme: Theme) =>
  createStyles({
    app: {
      height: '100%',

      '& [role=main]': {
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        height: '100%',
        borderRadius: '5px',
        boxShadow: '0 0 2px rgb(0 0 0 / 18%), 0 1px 2px rgb(0 0 0 / 36%)',
        minHeight: CONTENT_HEIGHT

        // [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        //   height: 'calc(100vh - '
        // }
      },

      '& .truncate': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: '1.3rem',

        '& p': {
          margin: 0,
          lineHeight: '1.3rem'
        }
      },

      '& .button-container': {
        display: 'flex',
        alignItems: 'center'
      },

      '& .messaging-menu-item': {
        minHeight: 'auto'
      },

      '&.mobile': {
        '& .app-header, & .app-header-button': {
          margin: 0,

          '&.app-header': {
            height: '45px'
          },

          '&.app-header-button': {
            marginBottom: '8px'
          }
        },
        '& h4': {
          visibility: 'hidden',
          height: 0
        }
      },

      '& .input': {
        borderRadius: '8px',

        '& > div': {
          borderRadius: '8px'
        },

        '&:not(.disabled)': {
          backgroundColor: 'white'
        }
      }
    },
    alert: {
      maxWidth: '1000px'
    },

    listItem: {
      backgroundColor: 'transparent',

      '&:hover': {
        '& .list-item-content': {
          backgroundColor: '#F5F7FA'
        }
      },

      '& .list-item-content': {
        transition: 'background-color .25s, border-color .25s',
        listStyle: 'none',
        display: 'flex',
        padding: '.6rem',
        alignItems: 'start',
        cursor: 'pointer',
        position: 'relative',
        borderRadius: '8px',
        margin: '.4rem .5rem 0',

        '&.active': {
          backgroundColor: '#F5F5F4 !important'
        }
      },

      '& img': {
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        overflow: 'hidden',
        marginRight: '.6rem'
      },

      '& .detail': {
        width: 'calc(100% - 4rem)',
        paddingTop: '.4rem'
      },

      '& .subject': {
        fontWeight: 500,
        fontSize: '1rem',
        lineHeight: '1.2rem'
      },

      '& .message': {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '.2rem',
        color: theme.palette.text.hint,
        gap: '0.2rem',
        maxHeight: '1.2rem',
        overflow: 'hidden',

        '& > p': {
          fontSize: '.8rem'
        }
      },

      '& .date': {
        minWidth: '5rem',
        textAlign: 'right',
        marginTop: '1px'
      },

      '& .unread > span': {
        transform: 'scale(.6)'
      },

      '& .latest': {
        pointerEvents: 'none',

        '& > p': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          height: '1.3rem'
        }
      }
    }
  })
);
