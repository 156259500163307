import { Menu } from '@material-ui/core';
import * as React from 'react';
import StatusFilters from './StatusFilters';
import styles from './styles';

interface Props {
  isOpen: boolean;
  anchorElement: HTMLElement | null;
}

const StatusFilterMenu: React.FC<Props> = ({ isOpen, anchorElement }) => {
  const [open, setOpen] = React.useState(isOpen);
  const classes = styles();

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Menu
      className={classes.menu}
      data-testid='status-filter-menu'
      anchorEl={anchorElement}
      open={open}
      onClose={() => setOpen(false)}
      elevation={2}
      keepMounted
    >
      {<StatusFilters />}
    </Menu>
  );
};

export default StatusFilterMenu;
