import { t } from 'i18next';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../context/app/AppContext';
import { Apartment, NotificationEmailInput } from '../graphql/generated/graphql';

interface NotificationEmailProps {
  apartments: Apartment[];
  category?: string;
}
const useNotificationEmail = ({
  apartments,
  category
}: NotificationEmailProps): NotificationEmailInput => {
  const { appContext } = useContext(AppContext);
  const { pathname } = useLocation();
  const bulletin = pathname.includes('bulletins');

  // TO-DO: Set support for tenant-customizable translations"
  const subjectKey =
    'notificationEmail.' +
    (bulletin
      ? appContext.organisation === 'hartela'
        ? 'hartelaBulletinSubject'
        : 'bulletinSubject'
      : 'subject');

  return {
    subject: t(subjectKey, {
      organisation: appContext.organisation
    }),
    projectName: appContext.project.name,
    apartments,
    category: category ? t('categories.' + category) : undefined
  };
};

export default useNotificationEmail;
