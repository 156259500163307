import React, { useContext } from 'react';
import StatusFilters from './StatusFilters/StatusFilters';
import styles from './styles';
import ApartmentFilterSelector from './ApartmentFilter/ApartmentFilterSelector';
import { AppContext } from '../../../context/app/AppContext';

const FiltersColumn: React.FC = () => {
  const classes = styles();
  const { appContext } = useContext(AppContext);

  return (
    <div className={`${classes.filters} filters-column`}>
      <StatusFilters />
      {appContext.apartments.length > 1 && <ApartmentFilterSelector />}
    </div>
  );
};

export default FiltersColumn;
