import { ClickAwayListener, IconButton } from '@material-ui/core';
import { HomeOutlined } from '@material-ui/icons';
import * as React from 'react';
import ApartmentFilterMenu from '../../../../../components/ApartmentFillterMenu/ApartmentFilterMenu';

export default function ApartmentFilterButton() {
  const [open, setOpen] = React.useState(false);
  const buttonRef = React.useRef(null);

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          setOpen(false);
        }}
      >
        <IconButton
          ref={buttonRef}
          className='filters'
          onClick={() => {
            setOpen(true);
          }}
          color='primary'
          aria-label='Show filters'
          component='span'
        >
          <HomeOutlined />
        </IconButton>
      </ClickAwayListener>
      <ApartmentFilterMenu isOpen={open} anchorElement={buttonRef.current} />
    </>
  );
}
