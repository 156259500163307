import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      marginRight: '10px'
    }
  })
);
