import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: '1.5rem',

      '&:not(.MuiAlert-root)': {
        display: 'block'
      },

      '& > div': {
        width: '50%'
      },

      [theme.breakpoints.down('md')]: {
        '& > div': {
          width: '100%'
        }
      }
    }
  })
);
