import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '1rem',

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',

        '& h4': {
          fontSize: '2rem',
          lineHeight: '1.2'
        }
      }
    },
    actionButtons: {
      display: 'flex',
      gap: '1rem'
    },
    button: {
      textTransform: 'none',

      '&.hidden': {
        display: 'none'
      },

      [theme.breakpoints.down('xs')]: {
        marginTop: '10px'
      }
    }
  })
);
