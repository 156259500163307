import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { IconButton, Typography } from '@material-ui/core';

import { KeyboardArrowDown } from '@material-ui/icons';
import { AppContext } from '../../context/app/AppContext';
import styles from './styles';

const Expander: FC = ({ children }) => {
  const classes = styles();
  const contentRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);
  const [expandable, setShowExander] = useState(false);
  const { appContext } = useContext(AppContext);

  const widthDifferenceThreshold = 50;

  const toggleExpander = () => {
    setExpanded(!expanded);
  };

  const setupExpander = () => {
    if (!contentRef.current || !parentRef.current) return;

    const widthDifference = parentRef.current.offsetWidth - contentRef.current.offsetWidth;

    setShowExander(
      parentRef.current.offsetWidth > 0 && widthDifference <= widthDifferenceThreshold
    );
  };

  function handleResize() {
    setupExpander();
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setupExpander();
  }, [appContext.activeBulletin?.uuid]);

  return (
    <div
      ref={parentRef}
      className={`${classes.expander} ${expanded ? 'expanded' : ''}`}
      data-testid='expander-parent'
    >
      <Typography ref={contentRef} className={`${classes.content} truncate`}>
        {children}
      </Typography>
      {expandable && (
        <IconButton onClick={toggleExpander} className={classes.expanderButton}>
          <KeyboardArrowDown />
        </IconButton>
      )}
    </div>
  );
};

export default Expander;
