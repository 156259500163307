import { SanitationParameters } from '../graphql/generated/graphql';
import { FormInputState } from '../hooks/useForm';

const DEFAULT_MANDATORY_MESSAGE = 'error.mandatoryField';
const EMPTY_WYSIWYG = '<p><br></p>';

export const MAX_FILE_SIZE = 5242880;

export const validateInputField = (inputFieldState: FormInputState): FormInputState => {
  const { value, values, file, mandatory, wysiwygContent, sanitationParameters } = inputFieldState;
  let errorMessage = '';
  inputFieldState.error = false;

  if (file) {
    const error = validateFile(file);
    if (error) errorMessage = error;
  } else if (mandatory) {
    if ((!values && isNullEmptyBlank(value)) || (values && !values.length))
      errorMessage = inputFieldState.errorMessage || DEFAULT_MANDATORY_MESSAGE;
  }

  if (wysiwygContent) {
    const error = validateWysiwygContent(
      String(value),
      sanitationParameters as SanitationParameters
    );
    if (error !== null) errorMessage = 'error.' + error;
  }

  inputFieldState.error = errorMessage.length > 0;
  inputFieldState.errorMessage = errorMessage;

  return inputFieldState;
};

export const validateFile = (file: File) => {
  let error = null;

  if (file.size > MAX_FILE_SIZE) error = 'error.fileMaxSizeExceeded';

  return error;
};

export const validateSearchInput = (searchInput: string) => {
  const input = unescape(trim(searchInput));
  // tags are not accepted in search, because for example <p> is always found
  const regex = new RegExp(/<(.*?)>/gm);

  return input.length >= 3 && !regex.test(input);
};

export const isMatch = (s1: string, s2: string) => {
  if (!s1 || !s2) return false;

  return unescape(s1).toLowerCase().includes(unescape(s2).toLowerCase());
};

export const bytesToMegaBytes = (bytes: number) => bytes / 1024 ** 2;

export const formatBytes = (bytes: number) => {
  if (bytes === 0) return '0 Bytes';

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));

  return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
};

const wysiwygContentToHtmlElement = (html: string) => {
  const template = document.createElement('template');
  template.innerHTML = trim(html);
  return template.content;
};

const validateWysiwygContent = (wysiwygStr: string, sanitationParameters: SanitationParameters) => {
  // human written tags (<>) are not accepted in message
  if (new RegExp(/&lt;(.*?)&gt;/gm).test(wysiwygStr)) return 'regex';

  const { allowedTags, allowedAttributes } = sanitationParameters;

  const content = wysiwygContentToHtmlElement(wysiwygStr);
  const linkRegex = new RegExp(/https?:\/\/|http?:\/\//gm);

  let error = null;
  content.querySelectorAll('*').forEach((e: HTMLElement) => {
    if (!e.tagName) return;

    const elementType = e.tagName.toLowerCase();

    if (!allowedTags.includes(elementType)) {
      error = 'invalidElement';
    }

    Array.from(e.attributes).forEach(({ name, value }) => {
      if (!name) return;

      const attributeName = name.toLowerCase();

      if (!allowedAttributes.includes(attributeName)) {
        error = 'invalidElement';
      } else if (attributeName === 'href' && (!value || !linkRegex.test(value))) {
        error = 'invalidHref';
      }
    });
  });

  return error;
};

const isNullEmptyBlank = (value: unknown) => {
  if (value === null || value === undefined) return true;
  else if (typeof value !== 'string') return false;
  else if (!trim(value).length || value === EMPTY_WYSIWYG) return true;

  const wysiwygMatch = value.match(new RegExp('<p>' + '(.*)' + '</p>'));

  if (wysiwygMatch && wysiwygMatch.length) return !trim(wysiwygMatch[1]).length;

  return false;
};

const trim = (str: string) => {
  str = str.replace(/^\s+/, '');
  return str.replace(/\s+$/, '').trim();
};

const unescape = (htmlStr: string) => {
  // change escaped tags to html tags
  return htmlStr.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
};
