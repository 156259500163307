import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { BORDER, CONTENT_HEIGHT } from '../../../styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      borderRight: BORDER,

      '& .list-container': {
        width: '100%',
        height: CONTENT_HEIGHT,
        overflowY: 'auto'
      },

      '& .bulletin-list-item': {
        backgroundColor: 'transparent',

        '&:hover': {
          '& .list-item-content': {
            backgroundColor: '#F5F7FA'
          }
        },

        '& .list-item-content': {
          transition: 'background-color .25s, border-color .25s',
          listStyle: 'none',
          display: 'flex',
          padding: '.6rem',
          alignItems: 'start',
          cursor: 'pointer',
          position: 'relative',
          borderRadius: '8px',
          margin: '.4rem .5rem 0',

          '&.active': {
            backgroundColor: '#F5F5F4 !important'
          }
        },

        '& img': {
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          overflow: 'hidden',
          marginRight: '.6rem'
        },

        '& .detail': {
          width: 'calc(100% - 4rem)',
          paddingTop: '.4rem'
        },

        '& .subject': {
          fontWeight: 500,
          fontSize: '1rem',
          lineHeight: '1.2rem'
        },

        '& .message': {
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '.2rem',
          color: theme.palette.text.hint,
          gap: '0.2rem',
          maxHeight: '1.2rem',
          overflow: 'hidden',

          '& > p': {
            fontSize: '.8rem'
          }
        },

        '& .date': {
          minWidth: '5rem',
          textAlign: 'right',
          marginTop: '1px'
        },

        '& .unread > span': {
          transform: 'scale(.6)'
        },

        '& .latest': {
          pointerEvents: 'none',

          '& > p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            height: '1.3rem'
          }
        }
      }
    }
  })
);
