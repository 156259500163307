import { Menu, MenuItem } from '@material-ui/core';
import * as React from 'react';
import { AppContext } from '../../context/app/AppContext';
import { Apartment } from '../../interfaces';

interface Props {
  isOpen: boolean;
  anchorElement: HTMLElement | null;
}

const ApartmentFilterMenu: React.FC<Props> = ({ isOpen, anchorElement }) => {
  const { appContext, dispatch } = React.useContext(AppContext);
  const [open, setOpen] = React.useState(isOpen);

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const onMenuItemClick = (e: React.MouseEvent) => {
    const value: number = parseInt((e.target as HTMLInputElement).value);

    dispatch({
      ...appContext,
      selectedApartmentIds: [value],
      activeConversation: undefined
    });
    setOpen(false);
  };

  return (
    <Menu
      className='messaging-menu'
      anchorEl={anchorElement}
      open={open}
      onClose={() => setOpen(false)}
      elevation={2}
      keepMounted
      data-testid='apartment-filter-menu'
    >
      {appContext.apartments.map((apartment: Apartment, i: number) => (
        <MenuItem
          className='messaging-menu-item'
          key={i}
          value={apartment.id}
          onClick={onMenuItemClick}
        >
          {apartment.name}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default ApartmentFilterMenu;
