import React, { ReactElement } from 'react';
import { Button, CircularProgress, IconButton } from '@material-ui/core';
import useStyles from './styles';

export interface ActionButtonProps {
  className?: string | '';
  loading?: boolean;
  onClick: () => void;
  icon?: ReactElement;
}

export const ActionButton: React.FC<ActionButtonProps> = (props) => {
  const { loading, children, className, icon, ...rest } = props;
  const classes = useStyles();

  return (
    <>
      {icon ? (
        <IconButton
          color='primary'
          aria-label='Send'
          component='span'
          className={`${className} ${classes.iconButton} ${loading ? 'loading' : ''}`}
          {...rest}
        >
          {loading && <CircularProgress className='progress' />}
          <div className='children'>{icon}</div>
        </IconButton>
      ) : (
        <Button
          variant='contained'
          color='primary'
          className={`${classes.button} ${loading ? 'loading' : ''} ${className}`}
          {...rest}
        >
          {loading && <CircularProgress className='progress' />}
          <div className='children'>{children}</div>
        </Button>
      )}
    </>
  );
};
