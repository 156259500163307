import React, { useContext } from 'react';
import { AppContext } from '../../../context/app/AppContext';
import styles from './styles';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { MOBILE_BREAKPOINT } from '../../../styles';
import NewBulletinForm from './NewBulletinForm/NewBulletinForm';
import NoActiveNotification from '../../../components/NoActiveNotification/NoActiveNotification';
import ActiveBulletin from './ActiveBulletin/ActiveBulletin';

const BulletinsColumn: React.FC = () => {
  const { appContext } = useContext(AppContext);
  const { createNew, activeBulletin } = appContext;
  const classes = styles();
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down(MOBILE_BREAKPOINT));

  if (mobileSize && !appContext.activeBulletin && !appContext.createNew) return null;

  const renderContent = () => {
    if (appContext.createNew) return <NewBulletinForm />;
    else if (appContext.activeBulletin) return <ActiveBulletin />;

    return <NoActiveNotification />;
  };

  return (
    <div
      className={`${classes.container} bulletin-column ${
        createNew || activeBulletin ? 'active' : ''
      }`}
      data-testid='bulletin-column'
    >
      {renderContent()}
    </div>
  );
};

export default BulletinsColumn;
