import { useState } from 'react';
import { Apartment, SanitationParameters } from '../graphql/generated/graphql';
import { validateInputField } from '../utils/validator';

export interface FormInputState {
  value?: string;
  values?: Apartment[];
  file?: File;
  mandatory?: boolean;
  error?: boolean;
  errorMessage?: string;
  wysiwygContent?: boolean;
  sanitationParameters?: SanitationParameters;
}

export type FormState = Record<string, FormInputState>;

interface ChangeEvent {
  name: string;
  value: string;
}

const useForm = (initialState: FormState, submitCallback: () => void) => {
  const [formState, setFormState] = useState<FormState>(initialState);

  const handleSubmit = () => {
    if (validate()) {
      submitCallback();
    }
  };

  const handleChange = (e: ChangeEvent) => {
    const { name, value } = e;
    const inputFieldState = { ...formState[name], value };

    setFormState({
      ...formState,
      [name]: validateInputField(inputFieldState)
    });
  };

  const handleFileChange = (file: File | undefined) => {
    const key = 'attachment';
    const inputFieldState = { ...formState[key], file };

    setFormState({
      ...formState,
      [key]: file ? validateInputField(inputFieldState) : { value: '', file }
    });
  };

  const validate = (): boolean => {
    let valid = true;
    let validatedFormState = { ...formState };

    for (const key in formState) {
      const inputFieldState = validateInputField(formState[key]);

      validatedFormState = {
        ...validatedFormState,
        [key]: inputFieldState
      };

      if (inputFieldState.error) valid = false;
    }

    setFormState(validatedFormState);
    return valid;
  };

  return {
    handleChange,
    handleFileChange,
    handleSubmit,
    formState,
    setFormState
  };
};

export default useForm;
