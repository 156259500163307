import { BulletinApartmentInformation } from '../graphql/generated/graphql';
import { Apartment } from '../interfaces';

// Converts an array of ApartmentsInfo into an array of Apartments
export const getApartmentsByApartmentsInfo = ({
  apartmentsInfo,
  allApartments
}: {
  apartmentsInfo?: BulletinApartmentInformation[];
  allApartments: Apartment[];
}) => {
  return apartmentsInfo?.map((a) => {
    return {
      id: a.apartmentId,
      name: allApartments.find((ap) => ap.id === a.apartmentId)?.name
    };
  }) as Apartment[];
};
