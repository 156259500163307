import * as React from 'react';
import Viewer from '../../../../../components/Wysiwyg/Viewer';
import styles from './styles';
import MessageAttachment from '../../../../../components/Attachment/MessageAttachment';
import { Attachment } from '../../../../../graphql/generated/graphql';
import { TRANSITION_TIME } from '../../../../../components/TransitionGroup/styles';
import { AppContext } from '../../../../../context/app/AppContext';

export interface MessageListItemProps {
  content: string;
  isYou: boolean;
  scrollToMe: boolean;
  animate: boolean;
  attachment?: Attachment;
}

const MessageListItem: React.FC<MessageListItemProps> = ({
  content,
  isYou,
  scrollToMe,
  animate,
  attachment
}) => {
  const classes = styles();
  const scrollRef = React.useRef<HTMLLIElement>(null);
  const { appContext } = React.useContext(AppContext);

  React.useEffect(() => {
    if (!scrollToMe || !scrollRef.current) return;

    // we have to wait for transitin to end
    setTimeout(
      () => {
        const element = scrollRef.current as HTMLLIElement;
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
      },
      appContext.updateListing ? 0 : TRANSITION_TIME
    );
  }, []);

  return (
    <li ref={scrollRef} className={`${classes.item} ${isYou ? 'isYou' : ''}`}>
      <div className={`speech-bubble ${animate ? 'animate' : ''}`}>
        <Viewer value={content} />
        {attachment && (
          <MessageAttachment
            url={attachment.url}
            name={attachment.filename}
            type={String(attachment.mimeType)}
          />
        )}
      </div>
    </li>
  );
};

export default MessageListItem;
