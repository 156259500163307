import { Badge, Typography } from '@material-ui/core';
import * as React from 'react';
import { AppContext } from '../../../../../context/app/AppContext';
import {
  Conversation,
  ConversationCategory,
  Message
} from '../../../../../graphql/generated/graphql';
import Viewer from '../../../../../components/Wysiwyg/Viewer';
import styles from './styles';
import globalStyles from '../../../../../styles';
import { t } from 'i18next';
import { formatShortDate } from '../../../../../utils/date';

const ConversationListItem: React.FC<Conversation> = ({
  uuid,
  subject,
  apartmentId,
  categoryUuid,
  latestMessage,
  isUnread
}) => {
  const globalClasses = globalStyles();
  const classes = styles();
  const { appContext, dispatch } = React.useContext(AppContext);
  const { sentDate, content, user } = latestMessage as Message;
  const apartmentName = appContext.apartments.find(
    (apartment) => apartment.id === apartmentId
  )?.name;
  const category = appContext.categories?.find(
    (category: ConversationCategory) => category.uuid === categoryUuid
  )?.name;

  const onClick = () => {
    dispatch({
      ...appContext,
      activeConversation: {
        conversationUuid: String(uuid),
        categoryUuid: String(categoryUuid),
        apartmentId: parseInt(String(apartmentId))
      },
      createNew: false
    });
  };

  const isActive = appContext.activeConversation?.conversationUuid === uuid;

  return (
    <li
      className={`${classes.item} ${globalClasses.listItem} ${
        isUnread ? 'item-unread' : ''
      } messaging-list-item`}
      onClick={onClick}
    >
      <div className={`list-item-content  ${isActive ? 'active' : ''}`}>
        <img src={user?.avatar || ''} alt='user' />
        <div className='detail'>
          <Typography className='subject truncate'>{subject}</Typography>

          <Typography className='category truncate'>{t('categories.' + category)}</Typography>
          {appContext.apartments.length > 1 && (
            <Typography className='apartment truncate'>{apartmentName}</Typography>
          )}
          <div className='message'>
            <Viewer className='latest truncate' value={content as string} />
            <Typography className='date'>{formatShortDate(sentDate)}</Typography>
          </div>
        </div>
        {isUnread && (
          <Badge
            overlap='rectangular'
            badgeContent=''
            className='unread'
            color='primary'
            data-testid='conversation-unread'
          />
        )}
      </div>
    </li>
  );
};

export default ConversationListItem;
