import React from 'react';
import styles from './styles';

export interface ViewerProps {
  value: string;
  className?: string;
}

export default function Viewer(props: ViewerProps) {
  const classes = styles();

  return (
    <div
      data-testid='wysiwyg-viewer'
      className={`${classes.viewer + ' ' + props.className}`}
      dangerouslySetInnerHTML={{ __html: props.value }}
    />
  );
}
