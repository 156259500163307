import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { BORDER } from '../../../styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    filters: {
      padding: '12px',
      borderRight: BORDER,

      '& .filter': {
        display: 'flex',
        width: '100%',
        padding: '10px',
        borderRadius: '8px',

        '&:not(:first-of-type)': {
          marginTop: '3px'
        },

        '&:not(.apartment-fiter)': {
          '&:hover': {
            backgroundColor: '#F5F7FA',

            '& a': {
              textDecoration: 'none !important'
            }
          }
        },

        '&.active': {
          backgroundColor: '#F5F5F4',
          '& a': {
            color: theme.palette.text.primary
          }
        },

        '& svg': {
          width: '1.75rem',
          height: '1.75rem',
          margin: '-.2rem .7rem 0 .3rem'
        }
      }
    },
    divider: {
      margin: '1rem 0'
    }
  })
);
