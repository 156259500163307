import { createContext, Dispatch } from 'react';
import { BulletinClipboardContextProps } from './interfaces';

export const initialBulletinClipboardContext: BulletinClipboardContextProps = {
  subject: '',
  content: '',
  apartments: []
};

export const BulletinClipboardContext = createContext<{
  bulletinClipboardContext: BulletinClipboardContextProps;
  dispatch: Dispatch<BulletinClipboardContextProps>;
}>({
  bulletinClipboardContext: initialBulletinClipboardContext,
  dispatch: () => initialBulletinClipboardContext
});
