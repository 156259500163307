import { TextField, Typography } from '@material-ui/core';
import { AppContext } from '../../../../context/app/AppContext';
import { Alert } from '@material-ui/lab';
import styles from './styles';
import { ActionButton } from '../../../../components/ActionButton/ActionButton';
import React, { useContext } from 'react';
import {
  ConversationCategory,
  ConversationStatusEnum,
  useCreateConversationMutation
} from '../../../../graphql/generated/graphql';
import useForm, { FormInputState, FormState } from '../../../../hooks/useForm';
import { Header } from '../Header/Header';
import { useTranslation } from 'react-i18next';
import { Apartment } from '../../../../interfaces';
import Editor from '../../../../components/Wysiwyg/Editor';
import Selector from '../../../../components/Selector/Selector';
import { TransitionGroup } from '../../../../components/TransitionGroup/TransitionGroup';
import useNotificationEmail from '../../../../hooks/useNotificationEmail';

const CreateConversationForm: React.FC = () => {
  const classes = styles();
  const { appContext, dispatch } = useContext(AppContext);
  const { t } = useTranslation();

  const initialFormState: FormState = {
    apartment: {
      value: appContext.apartments.length === 1 ? appContext.apartments[0].id : '',
      mandatory: true
    } as FormInputState,
    category: { value: String(appContext.categories[0].name), mandatory: true },
    subject: { value: '', mandatory: true },
    message: { value: '', mandatory: true },
    attachment: { value: '', file: undefined }
  };

  const [submitForm, { loading, error }] = useCreateConversationMutation({
    onCompleted: (data) => {
      const categoryUuid = appContext.categories?.find(
        (category: ConversationCategory) => category.name === formState.category.value
      )?.uuid;
      dispatch({
        ...appContext,
        activeConversation: {
          conversationUuid: data.createConversation.conversationUuid,
          categoryUuid: String(categoryUuid),
          apartmentId: parseInt(formState.apartment.value as string)
        },
        createNew: false,
        filters: { conversationStatus: ConversationStatusEnum.Open }
      });
    }
  });

  const submit = () => {
    const { apartment, subject, message, category, attachment } = formState;

    submitForm({
      variables: {
        input: {
          apartmentId: Number(apartment.value),
          category: String(category.value),
          subject: String(subject.value),
          message: { content: message.value },
          notification: notificationEmailInput
        },
        attachment: attachment.file
      }
    });
  };

  const { formState, handleChange, handleFileChange, handleSubmit, setFormState } = useForm(
    initialFormState,
    submit
  );
  const activeApartment = appContext.apartments.filter(
    (a) => a.id === Number(formState.apartment.value)
  )[0];
  const activeCategory = appContext.categories.filter(
    (c: ConversationCategory) => c.name === formState.category.value
  )[0];

  const notificationEmailInput = useNotificationEmail({
    apartments: [activeApartment],
    category: String(activeCategory.name)
  });

  const backButtonClicked = () => {
    dispatch({ ...appContext, createNew: false });
  };

  const onChange = (e: React.SyntheticEvent<{ value: unknown }>) => {
    const { name, value } = e.target as HTMLInputElement;

    handleChange({ name, value });
  };

  const onEditorContentChanged = (content: string) => {
    setFormState({
      ...formState,
      message: {
        value: content,
        wysiwygContent: true,
        sanitationParameters: appContext.sanitationParameters
      }
    });
  };

  return (
    <TransitionGroup in={!!appContext.createNew} className={classes.root}>
      <Header className={classes.header} showBackButton onBackButtonClicked={backButtonClicked}>
        <Typography variant='h6' className='text'>
          {t('createNewConversation')}
        </Typography>
      </Header>
      <div className={`${classes.content} content`}>
        {error && (
          <Alert className={classes.errorContainer} severity='error'>
            {t('error.FAILED_TO_CREATE_CONVERSATION')}
          </Alert>
        )}
        <form
          noValidate
          name='new-conversation-form'
          className={classes.form}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Selector
            id='apartment'
            state={formState.apartment}
            onChange={onChange}
            items={appContext.apartments.map((a: Apartment) => {
              return { key: String(a.id), label: a.name, value: a.id } as any; // eslint-disable-line
            })}
            disabled={appContext.apartments.length === 1}
          />

          <Selector
            id='category'
            state={formState.category}
            onChange={onChange}
            items={appContext.categories.map((c: ConversationCategory) => {
              return { key: c.uuid, label: t('categories.' + c.name), value: c.name } as any; // eslint-disable-line
            })}
          />

          <TextField
            name='subject'
            className={`${classes.formElement} input`}
            id='new-conversation-subject'
            label={t('subject')}
            variant='outlined'
            fullWidth
            required={formState.subject.mandatory}
            autoComplete='off'
            onChange={onChange}
            error={formState.subject.error}
            helperText={formState.subject.error && t('' + formState.subject.errorMessage)}
            InputLabelProps={{
              shrink: true
            }}
          />
          <Editor
            contentState={formState.message}
            fileState={formState.attachment}
            onChange={onEditorContentChanged}
            onFileChange={handleFileChange}
          />
          <ActionButton className={classes.createButton} loading={loading} onClick={handleSubmit}>
            {t('create')}
          </ActionButton>
        </form>
      </div>
    </TransitionGroup>
  );
};

export default CreateConversationForm;
