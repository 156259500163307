import { TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionButton } from '../../../../components/ActionButton/ActionButton';
import Multiselect from '../../../../components/Multiselect/Multiselect';
import { TransitionGroup } from '../../../../components/TransitionGroup/TransitionGroup';
import Editor from '../../../../components/Wysiwyg/Editor';
import { AppContext } from '../../../../context/app/AppContext';
import {
  Apartment,
  ConversationStatusEnum,
  useCreateBulletinMessageMutation
} from '../../../../graphql/generated/graphql';
import useForm, { FormInputState, FormState } from '../../../../hooks/useForm';
import { Header } from '../../../messaging/Conversation/Header/Header';
import styles from './styles';
import useNotificationEmail from '../../../../hooks/useNotificationEmail';
import {
  BulletinClipboardContext,
  initialBulletinClipboardContext
} from '../../../../context/bulletinClipboard/BulletinClipboardContext';

const NewBulletinForm: FC = () => {
  const classes = styles();
  const { appContext, dispatch } = useContext(AppContext);
  const { bulletinClipboardContext, dispatch: dispatchBulletinClipboardContext } =
    useContext(BulletinClipboardContext);
  const { t } = useTranslation();

  const initialFormState: FormState = {
    apartments: {
      values: [],
      mandatory: true
    } as FormInputState,
    subject: { value: bulletinClipboardContext.subject || '', mandatory: true },
    message: { value: bulletinClipboardContext.content || '', mandatory: true },
    attachment: { file: undefined }
  };

  const [submitForm, { loading, error }] = useCreateBulletinMessageMutation({
    onCompleted: () => {
      dispatch({
        ...appContext,
        updateListing: true,
        activeBulletin: undefined,
        filters: { conversationStatus: ConversationStatusEnum.Open }
      });
    }
  });

  const submit = () => {
    const { subject, message, attachment } = formState;
    const apartments = formState.apartments.values as Apartment[];

    submitForm({
      variables: {
        input: {
          apartmentIds: apartments.map((a: Apartment) => a.id) as number[],
          subject: String(subject.value),
          content: String(message.value),
          notification: noticationEmailInput,
          requiresAcknowledgement: true
        },
        attachment: attachment.file
      }
    });
  };

  const { formState, handleChange, handleFileChange, handleSubmit, setFormState } = useForm(
    initialFormState,
    submit
  );
  const noticationEmailInput = useNotificationEmail({
    apartments: formState.apartments.values as Apartment[]
  });

  const backButtonClicked = () => {
    dispatch({ ...appContext, createNew: false });
  };

  const onChange = (e: React.SyntheticEvent<{ value: unknown }>) => {
    const { name, value } = e.target as HTMLInputElement;

    handleChange({ name, value });
  };

  const onEditorContentChanged = (content: string) => {
    setFormState({
      ...formState,
      message: {
        value: content,
        wysiwygContent: true,
        sanitationParameters: appContext.sanitationParameters
      }
    });
  };

  const onMultiSelectChange = (apartments: Apartment[]) => {
    setFormState({
      ...formState,
      apartments: { values: apartments, mandatory: true } as FormInputState
    });
  };

  useEffect(() => {
    setFormState({
      ...formState,
      apartments: {
        ...formState.apartments,
        values: bulletinClipboardContext.apartments
      } as FormInputState,
      message: {
        ...formState.message,
        value: bulletinClipboardContext.content
      },
      subject: {
        ...formState.subject,
        value: bulletinClipboardContext.subject
      }
    });
  }, [bulletinClipboardContext]);

  return (
    <TransitionGroup in={!!appContext.createNew} className={classes.root}>
      <Header className={classes.header} showBackButton onBackButtonClicked={backButtonClicked}>
        <Typography variant='h6' className='text'>
          {t('createNewBulletin')}
        </Typography>
      </Header>
      <div className={`${classes.content} content`}>
        {error && (
          <Alert className={classes.errorContainer} severity='error'>
            {t('error.FAILED_TO_CREATE_BULLETIN_MESSAGE')}
          </Alert>
        )}
        <form
          noValidate
          name='new-conversation-form'
          className={classes.form}
          onSubmit={(e) => {
            e.preventDefault();
          }}
          encType='multipart/form-data'
        >
          <Multiselect
            options={appContext.apartments}
            onChange={onMultiSelectChange}
            state={formState.apartments}
          />

          <TextField
            name='subject'
            className={`${classes.formElement} input`}
            id='bullet-subject'
            data-testid='bullet-subject'
            label={t('subject')}
            variant='outlined'
            fullWidth
            required={formState.subject.mandatory}
            autoComplete='off'
            onChange={onChange}
            error={formState.subject.error}
            helperText={formState.subject.error && t('' + formState.subject.errorMessage)}
            InputLabelProps={{
              shrink: true
            }}
            value={formState.subject.value}
          />
          <Editor
            contentState={formState.message}
            fileState={formState.attachment}
            onChange={onEditorContentChanged}
            onFileChange={handleFileChange}
          />
          <ActionButton
            className={classes.createButton}
            loading={loading}
            onClick={() => {
              handleSubmit();
              dispatchBulletinClipboardContext(initialBulletinClipboardContext);
            }}
          >
            {t('create')}
          </ActionButton>
        </form>
      </div>
    </TransitionGroup>
  );
};

export default NewBulletinForm;
