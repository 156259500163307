import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: '#FCFCFD'
    },
    header: {
      backgroundColor: 'white',
      height: '3.5rem',

      '& .header-content': {
        height: 'auto'
      }
    },
    content: {
      padding: '20px',
      height: `calc(100% - 100px)`,
      overflow: 'auto'
    },
    heading: {
      fontSize: '1.2rem',
      lineHeight: '3.2rem'
    },
    form: {
      '& .ql-toolbar': {
        marginTop: '1rem'
      }
    },
    errorContainer: {
      marginBottom: '20px'
    },
    formElement: {
      display: 'block',

      '&:not(:first-of-type)': {
        marginTop: '1.5rem'
      }
    },
    createButton: {
      float: 'right'
    }
  })
);
