import React, { useContext } from 'react';
import { IconButton } from '@material-ui/core';
import useStyles from './styles';
import { ArrowBack } from '@material-ui/icons';
import { AppContext } from '../../../../context/app/AppContext';
import StatusActionMenu from './StatusActionMenu';

interface Props {
  className?: string;
  showBackButton?: boolean;
  onBackButtonClicked?: () => void;
}

export const Header: React.FC<Props> = ({
  className,
  showBackButton,
  onBackButtonClicked,
  children
}) => {
  const classes = useStyles();
  const { appContext } = useContext(AppContext);

  return (
    <div
      className={`${classes.header} ${className ? className : ''}`}
      style={{ justifyContent: appContext.activeConversation ? 'space-between' : 'start' }}
      data-testid='conversation-header'
    >
      {showBackButton && (
        <div className={`${classes.backButtonContainer} button-container`}>
          <IconButton
            onClick={onBackButtonClicked}
            color='primary'
            aria-label='Back to messages'
            component='span'
          >
            <ArrowBack />
          </IconButton>
        </div>
      )}
      <div className={`${classes.content} header-content`}>{children}</div>

      {appContext.activeConversation && <StatusActionMenu />}
    </div>
  );
};
