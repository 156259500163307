import React, { useRef, useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import styles from './styles';
import 'react-quill/dist/quill.snow.css';
import { FormHelperText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EditorAttachment from './EditorAttachment/EditorAttachment';
import { formatBytes, MAX_FILE_SIZE } from '../../utils/validator';
import { FormInputState } from '../../hooks/useForm';

export interface EditorProps {
  contentState: FormInputState;
  fileState: FormInputState;
  onChange: (_content: string) => void;
  onFileChange: (_file: File) => void;
}

const TOOLBAR_OPTIONS = [['bold', 'italic', 'underline', 'link']];

export default function Editor(props: EditorProps) {
  const { contentState, fileState } = props;
  const [value, setValue] = useState<string>(contentState.value || '');
  const reactQuillRef = useRef<ReactQuill>(null);
  const classes = styles();
  const { t } = useTranslation();

  useEffect(() => {
    // clearing textarea after submit
    if (!contentState.value || !contentState.value.length) {
      setValue('');
    } else setValue(contentState.value);
  }, [contentState.value]);

  // remove style attributes, so that also copy pasted content has change to go thru validator
  const stripStyles = (html: string) => {
    const htmlNode = document.createElement('div');
    htmlNode.innerHTML = html;
    htmlNode.querySelectorAll('*').forEach((node) => {
      node.removeAttribute('style');
    });

    return htmlNode.innerHTML;
  };

  const onChange = (content: string) => {
    const strippedContent = stripStyles(content);

    setValue(strippedContent);
    props.onChange(strippedContent);
  };

  let errorMsg = null;
  if (contentState.errorMessage) errorMsg = t(contentState.errorMessage);
  else if (fileState.errorMessage)
    errorMsg = t(fileState.errorMessage, { maxSize: formatBytes(MAX_FILE_SIZE) });

  return (
    <div className={classes.container}>
      <ReactQuill
        ref={reactQuillRef}
        data-testid='wysiwyg-editor'
        theme='snow'
        modules={{
          toolbar: {
            container: TOOLBAR_OPTIONS
          }
        }}
        value={value}
        placeholder={t('wysiwygPlaceholder')}
        onChange={onChange}
        className={`${classes.editor} ${contentState.errorMessage ? 'error' : ''}`}
      />

      <EditorAttachment onFileChange={props.onFileChange} file={fileState.file} />

      {errorMsg && (
        <FormHelperText className={`${classes.errorText} Mui-error`}>{errorMsg}</FormHelperText>
      )}
    </div>
  );
}
