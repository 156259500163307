import { withStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { ReactElement } from 'react';
import * as Sentry from '@sentry/react';
import styles from './styles';

type ErrorBoundaryStateType = {
  error?: Error;
  eventId: string;
};

type ErrorBoundaryPropsType = {
  className?: string;
  children: ReactElement;
};

class ErrorBoundary extends React.Component<ErrorBoundaryPropsType, ErrorBoundaryStateType> {
  constructor(props: ErrorBoundaryPropsType) {
    super(props);
    this.state = { error: undefined, eventId: '' };
  }

  // eslint-disable-next-line
  componentDidCatch(error: Error, errorInfo: any) {
    this.setState({ error });

    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    const { children } = this.props;
    const { error, eventId } = this.state;

    if (error) {
      return (
        <Alert severity='error'>
          {error.toString()} ({eventId})
        </Alert>
      );
    }

    return children;
  }
}

export default withStyles(styles)(ErrorBoundary);
