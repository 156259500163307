import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    attachmentContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      position: 'relative'
    },
    link: {
      border: 'none',
      '& .truncate': {
        fontSize: '.95rem !important'
      },

      '&:hover': {
        textDecoration: 'underline'
      }
    },

    linkTextContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '10px',

      '& svg': {
        marginRight: '8px'
      }
    },

    image: {
      width: 'auto',
      maxWidth: '100%',
      marginTop: '10px'
    }
  })
);
