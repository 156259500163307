import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const MOBILE_BREAKPOINT = 900;
export const BACKGROUND_COLOR = '#f9f9f9';
export const HIGHLIGHT_COLOR = '#ececec';
export const BORDER = '1px solid rgb(0 0 0 / 10%)';
export const HEADER_HEIGHT = '5rem';

export default makeStyles((theme: Theme) =>
  createStyles({
    bulletins: {
      '& .listing-column': {
        width: '35%'
      },

      '& .bulletin-column': {
        width: '65%'
      },

      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        '& .bulletin-column, .listing-column': {
          width: '100% !important',
          border: 'none'
        }
      }
    },
    button: {
      textTransform: 'none',

      '&.hidden': {
        display: 'none'
      },

      [theme.breakpoints.down('xs')]: {
        marginTop: '10px'
      }
    }
  })
);
