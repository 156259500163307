import { IconButton, Typography } from '@material-ui/core';
import * as React from 'react';
import styles from './styles';
import globalStyles from '../../../../../styles';
import { BulletinMessage } from '../../../../../graphql/generated/graphql';
import { AppContext } from '../../../../../context/app/AppContext';
import Viewer from '../../../../../components/Wysiwyg/Viewer';
import { formatShortDate } from '../../../../../utils/date';
import { t } from 'i18next';
import { ClientType } from '../../../../../constants';
import FileCopy from '@material-ui/icons/FileCopyOutlined';
import { BulletinClipboardContext } from '../../../../../context/bulletinClipboard/BulletinClipboardContext';
import { useContext } from 'react';
import { getApartmentsByApartmentsInfo } from '../../../../../utils/utils';

const BulletinListItem: React.FC<BulletinMessage> = (bulletin) => {
  const { uuid, subject, apartmentInfo, sentDate, user, content } = bulletin;
  const globalClasses = globalStyles();
  const classes = styles();
  const { appContext, dispatch } = useContext(AppContext);
  const { dispatch: dispatchBulletinContext } = useContext(BulletinClipboardContext);

  const onClick = () => {
    dispatch({
      ...appContext,
      createNew: false,
      activeBulletin: bulletin
    });
  };

  const getApartmentInfoText = () => {
    if (appContext.clientType !== ClientType.CoreClient) return null;

    const textKey = apartmentInfo.length > 1 ? 'apartmentsMultiple' : 'apartment';

    return (
      <Typography className='apartment truncate'>
        {`${apartmentInfo.length} ${t(textKey).toLowerCase()}`}
      </Typography>
    );
  };

  const isActive = appContext.activeBulletin?.uuid === uuid;

  return (
    <li className={`${classes.item} ${globalClasses.listItem}`} onClick={onClick}>
      <div className={`list-item-content  ${isActive ? 'active' : ''}`}>
        <img src={user?.avatar || ''} alt='user' />
        <div className='detail'>
          <Typography className='subject truncate'>{subject}</Typography>
          {getApartmentInfoText()}
          <div className='message'>
            <Viewer className='latest truncate' value={content as string} />
            <Typography className='date'>{formatShortDate(sentDate)}</Typography>
          </div>
        </div>
        {appContext.createNew && (
          <IconButton
            style={{ position: 'absolute', right: '0' }}
            onClick={(evt: React.SyntheticEvent<Element, Event>) => {
              evt.stopPropagation();
              dispatchBulletinContext({
                apartments: getApartmentsByApartmentsInfo({
                  apartmentsInfo: bulletin.apartmentInfo,
                  allApartments: appContext.apartments
                }),
                subject: subject || '',
                content: content || ''
              });
            }}
          >
            <FileCopy />
          </IconButton>
        )}
      </div>
    </li>
  );
};

export default BulletinListItem;
