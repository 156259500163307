import { createStyles, makeStyles, Theme } from '@material-ui/core';

const IMAGE_SIZE = '40px';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      listStyle: 'none',
      width: 'calc(100% - 30px)',
      margin: '0 15px',
      display: 'flex',
      alignItems: 'start',
      position: 'relative',
      flexDirection: 'column',
      padding: '15px 0',

      '& .info-content': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        lineHeight: '.5rem',
        position: 'relative'
      },

      '& .message-avatar': {
        minWidth: IMAGE_SIZE,
        maxWidth: IMAGE_SIZE,
        borderRadius: '50%',
        overflow: 'hidden',
        position: 'absolute'
      },

      '& .name': {
        marginLeft: '47px'
      },

      '& .time': {
        whiteSpace: 'nowrap',
        fontSize: '.8rem',
        color: theme.palette.text.hint,
        paddingRight: '7px'
      },

      '& .speech-bubble': {
        display: 'flex',
        backgroundColor: '#F2F4F7',
        padding: '12px',
        flexDirection: 'column',
        borderRadius: '0 10px 10px 10px',
        margin: '3px 0 0 45px',
        width: 'calc(100% - 70px)',

        '& p': {
          margin: 0,
          fontSize: '1.1rem'
        }
      },

      '& .msg': {
        font: 'inherit'
      }
    }
  })
);
