import React, { FC, useContext } from 'react';
import BulletinsColumn from './Bulletin';
import ListingColumn from './Listing';
import styles from './styles';
import AppHeader from '../../components/AppHeader/AppHeader';
import { AppContext } from '../../context/app/AppContext';
import { UserRightsType } from '../../graphql/generated/graphql';
import globalStyles from '../../styles';
import { Alert } from '@material-ui/lab';
import { t } from 'i18next';
import { BulletinClipboardContext } from '../../context/bulletinClipboard/BulletinClipboardContext';
import { Button } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import { getApartmentsByApartmentsInfo } from '../../utils/utils';

const Bulletins: FC = () => {
  const { appContext, dispatch } = useContext(AppContext);
  const { dispatch: dispatchBulletinContext } = useContext(BulletinClipboardContext);
  const classes = styles();
  const globalClasses = globalStyles();

  if (!appContext.userRights?.includes(UserRightsType.BulletinsRead)) {
    return (
      <Alert severity='error' className={globalClasses.alert}>
        {t('error.noUserRightsBulletins')}
      </Alert>
    );
  }

  return (
    <>
      <AppHeader
        titleKey='bulletins'
        newButtonKey={
          appContext.userRights?.includes(UserRightsType.BulletinsCreate) ? 'newBulletin' : null
        }
        additionalButton={
          appContext.activeBulletin ? (
            <Button
              onClick={() => {
                dispatchBulletinContext({
                  apartments: getApartmentsByApartmentsInfo({
                    apartmentsInfo: appContext.activeBulletin?.apartmentInfo,
                    allApartments: appContext.apartments
                  }),
                  subject: appContext.activeBulletin?.subject || '',
                  content: appContext.activeBulletin?.content || ''
                });
                dispatch({
                  ...appContext,
                  createNew: true,
                  activeConversation: undefined,
                  searchActive: false
                });
              }}
              variant='contained'
              color='primary'
              startIcon={<FileCopy />}
              className={`${classes.button} app-header-button ${
                appContext.createNew ? 'hidden' : ''
              }`}
            >
              {t('copyBulletin')}
            </Button>
          ) : undefined
        }
      />

      <div className={classes.bulletins} role='main'>
        <ListingColumn />
        <BulletinsColumn />
      </div>
    </>
  );
};

export default Bulletins;
