import React, { useMemo, useReducer } from 'react';
import { AppContext } from './AppContext';
import { AppContextProps, AppContextProviderProps, AppContextReducerType } from './interfaces';

const reduce = (
  previousContext: AppContextProps,
  contentextUpdate: AppContextProps
): AppContextProps => {
  return {
    ...previousContext,
    ...contentextUpdate
  };
};

const AppContextProvider: React.FC<AppContextProviderProps> = ({ children, initialContext }) => {
  const [appContext, dispatch] = useReducer<AppContextReducerType>(reduce, initialContext);

  const contextValue = useMemo(() => ({ appContext, dispatch }), [appContext, dispatch]);

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

export default AppContextProvider;
