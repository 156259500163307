import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { BORDER, CONTENT_HEIGHT, MOBILE_BREAKPOINT } from '../../../../styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: `calc(${CONTENT_HEIGHT} - 90px)`,
      overflowY: 'auto'
    },
    filterButtonsMobile: {
      width: '100%',
      borderBottom: BORDER,
      display: 'flex',
      justifyContent: 'flex-end',

      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        height: '3rem'
      }
    },
    listContainer: {
      '& > div': {
        height: 'auto'
      },

      [theme.breakpoints.down('md')]: {}
    },
    list: {
      listStyle: 'none',
      padding: '0',
      margin: '0'
    },
    loading: {
      height: '100%',
      display: 'flex',

      '& .progress': {
        margin: 'auto'
      }
    },
    alertContainer: {
      margin: '1rem 1rem 0'
    }
  })
);
