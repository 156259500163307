import React, { ChangeEvent, useEffect, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Apartment } from '../../graphql/generated/graphql';
import { t } from 'i18next';
import { FormInputState } from '../../hooks/useForm';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

interface MultiSelectProps {
  options: Apartment[];
  state: FormInputState;
  onChange: (_value: Apartment[] | null) => void;
}
const Multiselect: React.FC<MultiSelectProps> = ({
  options,
  state,
  onChange
}: MultiSelectProps) => {
  const [selectedOptions, setSelectedOptions] = useState<Apartment[]>(state.values || []);
  const allSelected = options.length === selectedOptions.length;
  const selectecAll = { name: 'Select all', id: -1 };
  const allOptions = [selectecAll].concat(options);

  const handleChange = (e: ChangeEvent, value: Apartment[]) => {
    let selected = value;
    if (value.includes(selectecAll)) selected = allSelected ? [] : options;

    setSelectedOptions(selected);
    return onChange(selected);
  };

  useEffect(() => {
    if (state.values) setSelectedOptions(state.values);
  }, [state.values]);

  // eslint-disable-next-line
  const optionRenderer = (option: Apartment, { selected }: any) => {
    const selectAllProps = option.id === -1 ? { checked: allSelected } : {};
    return (
      <>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
        {option.name}
      </>
    );
  };

  return (
    <Autocomplete
      multiple
      id='apartment-multiselect'
      value={selectedOptions}
      onChange={handleChange}
      options={allOptions}
      disableCloseOnSelect
      limitTags={3}
      getOptionLabel={(option) => option.name}
      renderOption={(option) =>
        optionRenderer(option, { selected: selectedOptions.map((a) => a.id).includes(option.id) })
      }
      renderInput={(params) => (
        <TextField
          {...params}
          className='input'
          variant='outlined'
          label={t('apartments')}
          required={state.mandatory}
          error={state.error}
          helperText={state.error && t('' + state.errorMessage)}
          InputLabelProps={{
            shrink: true
          }}
        />
      )}
    />
  );
};

export default Multiselect;
