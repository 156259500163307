import en from './en.json';
import en_GB from './en_GB.json';
import fi from './fi.json';
import cs from './cs.json';
import de from './de.json';
import es from './es.json';
import et from './et.json';
import fr from './fr.json';
import lt from './lt.json';
import nb from './nb.json';
import nl from './nl.json';
import ru from './ru.json';
import sk from './sk.json';
import sv from './sv.json';

export default { 'en-GB': en_GB, en, fi, cs, de, es, et, fr, lt, nb, nl, ru, sk, sv };
