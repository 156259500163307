import { Typography } from '@material-ui/core';
import React, { FC, useContext } from 'react';
import Viewer from '../../../../../components/Wysiwyg/Viewer';
import { AppContext } from '../../../../../context/app/AppContext';
import { BulletinMessage } from '../../../../../graphql/generated/graphql';
import { formatLongDate } from '../../../../../utils/date';
import MessageAttachment from '../../../../../components/Attachment/MessageAttachment';
import styles from './styles';

const BulletinItem: FC = () => {
  const { appContext } = useContext(AppContext);
  const { content, user, sentDate, attachment } = appContext.activeBulletin as BulletinMessage;
  const classes = styles();

  return (
    <div className={classes.container} data-testid='bulletin-item'>
      <div className='info-content'>
        <img
          src={String(user?.avatar)}
          alt='avatar'
          className='message-avatar'
          data-testid='user-avatar'
        />
        <Typography className='name truncate'>{user?.name}</Typography>
        <Typography className='time'>{formatLongDate(sentDate)}</Typography>
      </div>
      <div className={`speech-bubble`}>
        <Viewer value={String(content)} />
        {attachment && (
          <MessageAttachment
            url={attachment.url}
            name={attachment.filename}
            type={String(attachment.mimeType)}
          />
        )}
      </div>
    </div>
  );
};

export default BulletinItem;
