import * as React from 'react';
import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import i18next from 'i18next';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const global: any;

const getServiceUrl = () => {
  if (process.env.REACT_APP_MESSAGING_SERVICE_URL)
    return process.env.REACT_APP_MESSAGING_SERVICE_URL;
  else if (global.env) return global.env.MESSAGING_SERVICE_URL;

  return '';
};

const getSubscriptionUrl = () => {
  if (process.env.REACT_APP_MESSAGING_SUBSCRIPTION_URL)
    return process.env.REACT_APP_MESSAGING_SUBSCRIPTION_URL;
  else if (global.env) return global.env.MESSAGING_SUBSCRIPTION_URL;

  return '';
};

const createWsLink = ({ token, tenantId, projectId, organisation }: ApolloProps) => {
  return new GraphQLWsLink(
    createClient({
      url: getSubscriptionUrl(),
      connectionParams: {
        Authorization: token,
        tenantid: tenantId,
        projectid: projectId,
        organisation
      }
    })
  );
};

const splitLink = (props: ApolloProps) =>
  split(({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  }, createWsLink(props));

const createAuthLink = ({ token, tenantId, projectId, organisation }: ApolloProps) => {
  return setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: token,
        tenantId,
        projectId,
        organisation,
        'Apollo-Require-Preflight': true,
        'accept-language': i18next.language || 'en'
      }
    };
  });
};

const createApolloClient = (props: ApolloProps) => {
  const authLink = createAuthLink(props).concat(splitLink(props));
  const uploadLink = createUploadLink({ uri: getServiceUrl() });

  return new ApolloClient({
    link: ApolloLink.from([authLink, uploadLink]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache'
      },
      query: {
        fetchPolicy: 'no-cache'
      }
    }
  });
};

interface ApolloProps {
  token: string;
  tenantId: string;
  projectId: string;
  organisation: string;
}

const MessagingApolloProvider: React.FC<ApolloProps> = (props) => {
  const client = createApolloClient(props);
  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};

export default MessagingApolloProvider;
