import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    filter: {
      paddingRight: '0 !important',
      maxWidth: 'calc(100% - 10px)',
      minHeight: 'auto',

      '& .button': {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
      },

      '& .down-arrow': {
        margin: '0 0 0 15px !important'
      }
    }
  })
);
