import { Typography } from '@material-ui/core';
import * as React from 'react';
import { AppContext } from '../../../../../context/app/AppContext';
import Viewer from '../../../../../components/Wysiwyg/Viewer';
import { formatShortDate } from '../../../../../utils/date';
import { Conversation, Message } from '../../../../../graphql/generated/graphql';
import globalStyles from '../../../../../styles';

export interface SearchListItemProps {
  conversation: Conversation;
  message: Message;
  messageMatch?: boolean;
}

const SearchListItem: React.FC<SearchListItemProps> = ({ conversation, message, messageMatch }) => {
  const { appContext, dispatch } = React.useContext(AppContext);
  const { uuid, subject, categoryUuid, apartmentId } = conversation;
  const { uuid: messageUuid, sentDate, content, user } = message;
  const globalClasses = globalStyles();

  const onClick = () => {
    dispatch({
      ...appContext,
      activeConversation: {
        conversationUuid: String(uuid),
        categoryUuid: String(categoryUuid),
        apartmentId: parseInt(String(apartmentId)),
        messageUuid: messageMatch ? messageUuid : undefined
      },
      createNew: false
    });
  };

  const isActive = appContext.activeConversation?.messageUuid === messageUuid;

  return (
    <li className={globalClasses.listItem} onClick={onClick}>
      <div className={`list-item-content  ${isActive ? 'active' : ''}`}>
        {user?.avatar && <img src={user?.avatar} alt='user' />}
        <div className='detail'>
          <Typography className='subject truncate'>{subject}</Typography>
          <div className='message'>
            <Viewer className='content truncate' value={content as string} />
            <Typography className='date'>{formatShortDate(sentDate)}</Typography>
          </div>
        </div>
      </div>
    </li>
  );
};

export default SearchListItem;
