import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from './translations';

const initI18N = (lng: string) => {
  const language = lng.replace('_', '-') || '';
  i18n.use(initReactI18next).init({
    lng: language,
    resources,
    debug: false,
    fallbackLng: 'en',
    load: 'all',
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    }
  });
};

export default initI18N;
