import React, { FC, useContext } from 'react';
import { TransitionGroup } from '../../../../components/TransitionGroup/TransitionGroup';
import { AppContext } from '../../../../context/app/AppContext';
import BulletinHeader from './BulletinHeader/BulletinHeader';
import BulletinItem from './BulletinItem/BulletinItem';
import styles from './styles';

const ActiveBulletin: FC = () => {
  const { appContext } = useContext(AppContext);
  const classes = styles();

  return (
    <TransitionGroup in={!!appContext.activeBulletin}>
      <div className={classes.container}>
        <BulletinHeader />
        <BulletinItem />
      </div>
    </TransitionGroup>
  );
};

export default ActiveBulletin;
