import React, { useMemo, useReducer } from 'react';
import { BulletinClipboardContext } from './BulletinClipboardContext';
import {
  BulletinClipboardContextProps,
  BulletinClipboardContextProviderProps,
  BulletinClipboardContextReducerType
} from './interfaces';

const reduce = (
  previousContext: BulletinClipboardContextProps,
  contentUpdate: BulletinClipboardContextProps
): BulletinClipboardContextProps => {
  return {
    ...previousContext,
    ...contentUpdate
  };
};

const BulletinClipboardContextProvider: React.FC<BulletinClipboardContextProviderProps> = ({
  children,
  initialContext
}) => {
  const [bulletinClipboardContext, dispatch] = useReducer<BulletinClipboardContextReducerType>(
    reduce,
    initialContext
  );

  const contextValue = useMemo(
    () => ({ bulletinClipboardContext, dispatch }),
    [bulletinClipboardContext, dispatch]
  );

  return (
    <BulletinClipboardContext.Provider value={contextValue}>
      {children}
    </BulletinClipboardContext.Provider>
  );
};

export default BulletinClipboardContextProvider;
