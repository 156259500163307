import { createStyles, makeStyles } from '@material-ui/core';
import { BORDER } from '../../../../../styles';

export default makeStyles(() =>
  createStyles({
    container: {
      position: 'relative',
      borderBottom: BORDER,

      '& .message-search': {
        margin: '12px',
        width: 'calc(100% - 24px)'
      },

      '& .message-search-clear': {
        position: 'absolute',
        top: '15px',
        right: '15px'
      }
    }
  })
);
