import { createStyles, makeStyles } from '@material-ui/core';
import { CONTENT_HEIGHT } from '../../../../styles';

export default makeStyles(() =>
  createStyles({
    root: {
      height: `calc(${CONTENT_HEIGHT} - 90px)`,
      overflowY: 'auto'
    },
    container: {
      width: '100%',

      '& ul, ol': {
        listStyle: 'none',
        margin: '0',
        padding: '0'
      },

      '& .search-fade': {
        transition: 'opacity .5s',
        opacity: 0,

        '&.enter-done': {
          opacity: 1
        }
      }
    }
  })
);
