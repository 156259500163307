import { createStyles, makeStyles } from '@material-ui/core';

export const TRANSITION_TIME = 500;

export default makeStyles(() =>
  createStyles({
    root: {
      height: '100%',

      '& .messaging-transition': {
        height: '100%',
        transition: `opacity ${TRANSITION_TIME}ms`,
        opacity: 0,

        '&.enter-done': {
          opacity: 1
        }
      }
    }
  })
);
