import { createStyles, makeStyles, Theme } from '@material-ui/core';

const IMAGE_SIZE = '40px';

export default makeStyles((theme: Theme) =>
  createStyles({
    group: {
      listStyle: 'none',
      display: 'flex',
      padding: '25px 0 15px',
      alignItems: 'end',
      position: 'relative',
      flexDirection: 'row',
      width: '65%',

      '& .message-avatar': {
        minWidth: IMAGE_SIZE,
        maxWidth: IMAGE_SIZE,
        borderRadius: '50%',
        overflow: 'hidden',
        marginRight: '5px',
        position: 'absolute',
        top: '0'
      },

      '& .info-content': {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        top: '0',
        justifyContent: 'space-between',
        width: '100%',
        lineHeight: '.5rem'
      },

      '& .time': {
        fontSize: '.8rem',
        color: theme.palette.text.hint,
        whiteSpace: 'nowrap'
      },

      '& ul, ol': {
        width: '100%',
        padding: 0
      },

      '&.isYou': {
        float: 'right',
        flexDirection: 'row-reverse',

        '& .info-content': {
          marginLeft: '0'
        }
      },

      '&:not(.isYou)': {
        '& .info-content': {
          marginLeft: '50px',
          width: 'calc(100% - 40px)'
        },

        '& ul': {
          paddingLeft: IMAGE_SIZE
        }
      },

      '& p': {
        fontSize: '.9rem'
      },

      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    }
  })
);
