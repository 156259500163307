import {
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core';
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
import { Alert } from '@material-ui/lab';
import { t } from 'i18next';
import React, { useContext } from 'react';
import { AppContext } from '../../../../context/app/AppContext';
import {
  ConversationStatusEnum,
  UserRightsType,
  useSetConversationStatusMutation
} from '../../../../graphql/generated/graphql';
import styles from './styles';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';
import { ConversationStatus, allStatuses } from '../../../../utils/statuses';

const StatusActionMenu = () => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [action, setAction] = React.useState<ConversationStatusEnum>();
  const { appContext, dispatch } = useContext(AppContext);
  const menuButtonRef = React.useRef(null);
  const classes = styles();

  const actions = allStatuses
    .filter((s) => s.type !== appContext.filters.conversationStatus)
    .map((a) => {
      return {
        ...a,
        label: a.label + 'Action'
      };
    });

  const [setConversationStatus, { loading, error }] = useSetConversationStatusMutation({
    onCompleted: () => {
      dispatch({
        ...appContext,
        activeConversation: undefined,
        updateListing: true,
        searchActive: false
      });
    }
  });

  const onActionClicked = (event: React.MouseEvent, status: ConversationStatus) => {
    event.preventDefault();

    if (loading) return;

    setAction(status.type as ConversationStatusEnum);
    setConfirmDialogOpen(true);
  };

  const handleConfirm = () => {
    if (!action) return;
    setConfirmDialogOpen(false);

    setConversationStatus({
      variables: {
        input: {
          conversationUuid: String(appContext.activeConversation?.conversationUuid),
          status: action
        }
      }
    });
    setAction(undefined);
  };

  const handleDisagree = () => {
    setConfirmDialogOpen(false);
  };

  if (!appContext.userRights?.includes(UserRightsType.MessagesUpdateStatus)) return null;

  return (
    <div className={`${classes.actionMenuContainer} button-container`}>
      <ClickAwayListener
        onClickAway={() => {
          setMenuOpen(false);
        }}
      >
        <IconButton
          ref={menuButtonRef}
          className='filters'
          onClick={() => {
            setMenuOpen(true);
          }}
          color='primary'
          aria-label='Show filters'
          component='span'
        >
          <MenuOutlinedIcon />
        </IconButton>
      </ClickAwayListener>

      <Menu
        className={classes.menu}
        data-testid='status-action-menu'
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        elevation={2}
        anchorEl={menuButtonRef.current}
        keepMounted
      >
        {actions.map((s: ConversationStatus, i) => {
          return (
            <MenuItem
              data-testid={`status-action-${s.label}`}
              key={`status-action-${i}`}
              className={`status-action messaging-menu-item`}
            >
              <Link
                href='#'
                onClick={(e: React.MouseEvent) => {
                  onActionClicked(e, s);
                }}
              >
                {t(s.label)}
              </Link>
            </MenuItem>
          );
        })}
      </Menu>

      <Dialog
        open={confirmDialogOpen}
        data-testid='status-action-confirm-dialog'
        className={`${classes.confirmDialog} ${loading && ' loading'}`}
        onClose={() => {
          setConfirmDialogOpen(false);
        }}
      >
        <DialogContent>
          <Typography className='confirm-dialog-text' variant='h6'>{`${t(
            'confirm.conversationStatus',
            {
              status: t(`status.${action}`)
            }
          )}`}</Typography>
          {error && (
            <Alert className={classes.error} severity='error'>
              {t('error.setConversationStatus')}
            </Alert>
          )}
          {loading && <LoadingSpinner />}
        </DialogContent>
        <DialogTitle></DialogTitle>
        <DialogActions className='confirm-dialog-actions'>
          <Button onClick={handleDisagree} variant='outlined' color='primary'>
            {t('disagree')}
          </Button>
          <Button onClick={handleConfirm} variant='outlined' color='primary' autoFocus>
            {t('agree')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default StatusActionMenu;
