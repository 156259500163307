import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useLocation } from 'react-router-dom';

const NoActiveNotification: React.FC = () => {
  const classes = styles();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const textKey = !pathname.includes('bulletins')
    ? 'info.noConversationSelected'
    : 'info.noBulletinSelected';

  return (
    <div className={classes.emptyConversation}>
      <Typography>{t(textKey)}</Typography>
      <InfoOutlinedIcon data-testid='info-icon' className={classes.infoIcon} />
    </div>
  );
};

export default NoActiveNotification;
