import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { BORDER, HEADER_HEIGHT } from '../../../../styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    header: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      borderBottom: BORDER,
      height: HEADER_HEIGHT,
      backgroundColor: 'white'
    },
    content: {
      marginLeft: '1rem',
      height: HEADER_HEIGHT,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',

      '& p': {
        lineHeight: '1.3rem'
      },
      '& .apartment, .category': {
        color: theme.palette.primary.main,
        fontSize: '.9rem'
      },
      '& .subject, .apartment, .category': {
        //width: 'calc(100% - 5px)'
      }
    },
    menu: {
      position: 'relative',

      '& ul': {
        padding: '.5rem'
      },

      '& p': {
        display: 'flex',

        '&:not(:last-child)': {
          paddingBottom: '6px'
        }
      },

      '& svg': {
        paddingRight: '10px'
      },

      '& .status-action a': {
        textDecoration: 'none !important'
      }
    },
    backButtonContainer: {
      marginLeft: '.3rem',
      marginRight: '-.5rem'
    },
    actionMenuContainer: {
      width: '100%',
      justifyContent: 'end',
      marginRight: '5px'
    },
    confirmDialog: {
      '&.loading': {
        '& .confirm-dialog-text, .confirm-dialog-actions': {
          visibility: 'hidden'
        },

        '& .loading-spinner': {
          position: 'absolute',
          top: '0',
          left: 'calc(50% - 20px)'
        }
      }
    },
    error: {
      marginTop: '10px'
    }
  })
);
