import React, { useContext, useEffect } from 'react';
import { Alert, Color } from '@material-ui/lab';
import styles from './styles';
import {
  Conversation,
  ConversationStatusEnum,
  useConversationsQuery,
  useMessageCreatedSubscription
} from '../../../../graphql/generated/graphql';
import { AppContext } from '../../../../context/app/AppContext';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';
import ConversationListItem from './ConversationListItem/ConversationListItem';
import { useTranslation } from 'react-i18next';
import { getErrorText, logError } from '../../../../utils/errors';
import { TransitionGroup } from '../../../../components/TransitionGroup/TransitionGroup';

interface AlertProps {
  severity: Color;
  message: string;
}

const ConversationList: React.FC = () => {
  const { appContext, dispatch } = useContext(AppContext);
  const { data, error, loading, refetch } = useConversationsQuery({
    variables: {
      apartmentIds: appContext.selectedApartmentIds as number[],
      filters: appContext.filters || { conversationStatus: ConversationStatusEnum.Open }
    }
  });
  const { data: subscriptionData } = useMessageCreatedSubscription({
    variables: { apartmentId: (appContext.selectedApartmentIds as number[])[0] }
  });
  const classes = styles();
  const { t } = useTranslation();

  useEffect(() => {
    refetch();
  }, [subscriptionData?.messageCreated?.message?.uuid]);

  useEffect(() => {
    if (!appContext.updateListing) return;

    refetch();
    dispatch({ ...appContext, updateListing: false });
  }, [appContext.updateListing]);

  const getAlert = () => {
    if (error) {
      logError(error);
      return { severity: 'error', message: getErrorText(error) };
    } else if (!data?.conversations?.length) {
      return { severity: 'info', message: t('info.noConversationsFound') };
    }
    return null;
  };

  const renderContent = () => {
    if (loading) return <LoadingSpinner />;
    else {
      const alert = getAlert() as AlertProps;

      if (alert) {
        return (
          <Alert className={classes.alertContainer} severity={alert.severity}>
            {alert.message}
          </Alert>
        );
      }

      return (
        <TransitionGroup in={!loading && !error}>
          <ul className={classes.list}>
            {(data?.conversations as Conversation[]).map(
              (conversation: Conversation, i: number) => {
                return <ConversationListItem key={i} {...conversation} />;
              }
            )}
          </ul>
        </TransitionGroup>
      );
    }
  };

  return (
    <div className={classes.container} data-testid='conversation-list'>
      <div className={`${classes.listContainer} list-container`}>{renderContent()}</div>
    </div>
  );
};

export default ConversationList;
