import { Button, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { t } from 'i18next';
import React, { FC, useContext } from 'react';
import { AppContext } from '../../context/app/AppContext';
import styles from './styles';

interface AppHeaderProps {
  titleKey: string;
  newButtonKey: string | null;
  additionalButton?: JSX.Element;
}

const AppHeader: FC<AppHeaderProps> = ({
  titleKey,
  newButtonKey,
  additionalButton
}: AppHeaderProps) => {
  const { appContext, dispatch } = useContext(AppContext);
  const classes = styles();

  return (
    <div className={`${classes.header} app-header`} role='heading'>
      <Typography variant='h4'>{t(titleKey)}</Typography>

      {appContext.initialized && newButtonKey && (
        <div className={classes.actionButtons}>
          {additionalButton}
          <Button
            onClick={() => {
              dispatch({
                ...appContext,
                createNew: true,
                activeConversation: undefined,
                searchActive: false
              });
            }}
            variant='contained'
            color='primary'
            startIcon={<AddIcon />}
            className={`${classes.button} app-header-button ${
              appContext.createNew ? 'hidden' : ''
            }`}
          >
            {t(newButtonKey)}
          </Button>
        </div>
      )}
    </div>
  );
};

export default AppHeader;
