import { createContext, Dispatch } from 'react';
import { ClientType } from '../../constants';
import { ConversationStatusEnum } from '../../graphql/generated/graphql';
import { Project } from '../../interfaces';
import { AppContextProps } from './interfaces';

export const initialAppContext: AppContextProps = {
  organisation: '',
  project: {} as Project,
  apartments: [],
  categories: [],
  createNew: false,
  filters: { conversationStatus: ConversationStatusEnum.Open },
  clientType: ClientType.CoreClient
};

export const AppContext = createContext<{
  appContext: AppContextProps;
  dispatch: Dispatch<AppContextProps>;
}>({ appContext: initialAppContext, dispatch: () => initialAppContext });
