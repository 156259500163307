import { useMediaQuery, useTheme } from '@material-ui/core';
import React, { useContext } from 'react';
import { AppContext } from '../../../../context/app/AppContext';
import ApartmentFilterButton from './buttons/ApartmentFilterButton';
import StatusFilterButton from './buttons/StatusFilterButton';
import styles from './styles';

const MobileFilters: React.FC = () => {
  const theme = useTheme();
  const show = useMediaQuery(theme.breakpoints.down('md'));
  const classes = styles();
  const { appContext } = useContext(AppContext);

  if (!show) return null;

  return (
    <div className={classes.container}>
      <StatusFilterButton />
      {appContext.apartments.length > 1 && <ApartmentFilterButton />}
    </div>
  );
};

export default MobileFilters;
