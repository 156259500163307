import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    navigation: {
      marginBottom: '20px',

      [theme.breakpoints.down('xs')]: {
        marginBottom: '15px'
      }
    }
  })
);
