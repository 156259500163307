import React, { useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Tab, Tabs } from '@material-ui/core';
import styles from './styles';
import { ClientType, Paths } from '../../constants';
import { t } from 'i18next';
import { AppContext } from '../../context/app/AppContext';

const Navigation: React.FC = () => {
  const { appContext, dispatch } = useContext(AppContext);
  const classes = styles();
  const history = useHistory();
  const { pathname } = useLocation();

  const getCurrentPath = () => {
    if (!Object.values(Paths).includes(pathname as Paths)) return Paths.ApartmentMessages;

    return pathname;
  };

  const handleChange = (value: string) => {
    history.push(value);

    dispatch({
      ...appContext,
      initialized: false,
      createNew: false,
      activeBulletin: undefined,
      activeConversation: undefined
    });
  };

  if (appContext.clientType === ClientType.Mobile) return null;

  return (
    <Tabs
      role='navigation'
      value={getCurrentPath()}
      onChange={(e, value) => {
        handleChange(value);
      }}
      aria-label='messaging navigation'
      className={classes.navigation}
    >
      <Tab
        label={t('apartmentMessages')}
        value={Paths.ApartmentMessages}
        component={Link}
        to={Paths.ApartmentMessages}
      />
      <Tab label={t('bulletins')} value={Paths.Bulletins} component={Link} to={Paths.Bulletins} />
    </Tabs>
  );
};

export default Navigation;
