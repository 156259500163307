import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => {
  return createStyles({
    item: {
      '& .apartment, .category': {
        color: theme.palette.primary.main,
        fontSize: '.9rem'
      }
    }
  });
});
