import { ApolloError } from '@apollo/client';
import i18next from 'i18next';

const DEFAULT_ERROR_KEY = 'default';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getErrorText = (error: ApolloError | any) => {
  let errorKey;
  try {
    if (error?.graphQLErrors && error?.graphQLErrors.length)
      errorKey = error?.graphQLErrors[0].extensions.code;
    else errorKey = error.networkError.result.errors[0].extensions.code;
  } catch (e) {
    errorKey = DEFAULT_ERROR_KEY;
  }

  if (!i18next.exists('error.' + errorKey)) errorKey = DEFAULT_ERROR_KEY;

  return i18next.t('error.' + errorKey);
};

export const logError = (error: ApolloError | undefined) => {
  if (!error) return;

  console.error(error);
};
