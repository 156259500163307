import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    container: {
      padding: '3px 12px 12px'
    },
    form: {
      position: 'relative',

      '& button': {
        textTransform: 'none'
      }
    },
    errorContainer: {
      position: 'absolute',
      marginTop: '-10px',
      marginLeft: '-10px',
      border: 'none',
      width: 'calc(100% - 150px)'
    },
    formElement: {
      display: 'block',
      marginTop: '1rem'
    },
    textarea: {
      opacity: 0,
      position: 'absolute'
    },
    sendButton: {
      position: 'absolute',
      bottom: '2px',
      right: '2px'
    }
  })
);
