import { createStyles, makeStyles } from '@material-ui/core/styles';

const progressSize = 25;

export default makeStyles(() =>
  createStyles({
    iconButton: {
      '& .children': {
        width: '1em',
        height: '1em'
      },

      '&.loading': {
        pointerEvents: 'none',

        '& .children': {
          visibility: 'hidden',
          margin: 'auto'
        }
      },

      '& .progress': {
        width: `${progressSize}px !important`,
        height: `${progressSize}px !important`,
        position: 'absolute',
        left: `calc(50% - ${progressSize / 2}px)`,
        top: `calc(50% - ${progressSize / 2}px)`
      }
    },
    button: {
      padding: '8px 10px',
      height: '40px',
      minWidth: '80px',
      position: 'relative',
      textTransform: 'none',

      '&.loading': {
        pointerEvents: 'none',
        paddingTop: '8px',

        '& .children': {
          visibility: 'hidden',
          margin: 'auto'
        }
      },

      '& .progress': {
        width: `${progressSize}px !important`,
        height: `${progressSize}px !important`,
        position: 'absolute',
        left: `calc(50% - ${progressSize / 2}px)`,
        top: '7px',
        color: 'white'
      }
    }
  })
);
