import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    attachmentContainer: {
      position: 'absolute',
      top: '-4px',
      left: '115px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: 'calc(100% - 115px)',

      '& .attachment-icon, .attachment-clear': {
        color: theme.palette.text.primary,
        background: 'none !important',
        '&:hover, &:active': {
          color: theme.palette.primary.main
        }
      }
    },

    attachmentName: {
      maxWidth: 'calc(100% - 10px)',
      marginLeft: '8px',
      color: theme.palette.grey[600],
      fontSize: '.9rem'
    },

    attachmentClear: {}
  })
);
